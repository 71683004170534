import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { IoReloadOutline } from "react-icons/io5";
import { trackClickEvents } from "../../../../../services/mixpanel/events/trackClickEvents";
import { EMRButton as CancelButton } from "../../../Button";
import { useMedTrack } from "../../hooks/useMedTrack";
import { requestRecalculateRoute } from "../../utils/requestRecalculateRoute";
import {
  ButtonsContainer,
  ConfirmButton,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
} from "./styles";

export function RecalculateRouteButton({ onRecalculateRoute, ...rest }) {
  const { selectedMedtrackType } = useMedTrack();

  async function handleRecalculateRoute(e) {
    trackClickEvents({
      tagName: "Medtrack",
      eventName: "interação de navegação",
      content: {
        NomeDoBotao: "Recalcular Rota",
      },
    });
    await requestRecalculateRoute(selectedMedtrackType.id);
    await onRecalculateRoute(e);
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <ConfirmButton {...rest}>
          <IoReloadOutline size={24} />
          Recalcular
        </ConfirmButton>
      </Dialog.Trigger>

      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <DialogTitle>Deseja recalcular rota?</DialogTitle>
            <DialogDescription>
              Ao recalcular rota nossa inteligência artificial irá repriorizar
              todo seu cronograma a partir de agora, de acordo com sua metas e
              disponibilidade de tempo.
            </DialogDescription>
            <ButtonsContainer>
              <Dialog.Close asChild aria-label="Close">
                <CancelButton
                  fontSize="lg"
                  fullSized
                  mediumWeight
                  mode="cancel"
                >
                  Cancelar
                </CancelButton>
              </Dialog.Close>
              <Dialog.Close asChild>
                <ConfirmButton onClick={handleRecalculateRoute} fullSized>
                  <IoReloadOutline size={24} />
                  Recalcular Rota
                </ConfirmButton>
              </Dialog.Close>
            </ButtonsContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
