import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { medtrackApi } from "../../../services/medtrack";
import GoalsModal from "../../components/classrooms/goalsModal";
import {
  allowUserAccess,
  hasMedtrackSchedule,
} from "../../processes/medTrackProcess";
import { toast } from "../Toast";
import { CheckIfIsLoading } from "./CheckIfIsLoading";
import { Skeleton } from "./components";
import { OnboardingRevisionModal } from "./components/OnboardingRevisionModal";
import { MedTrackProvider } from "./hooks/useMedTrack";
import { FirstAccessScreen } from "./screens/FirstAccessScreen";
import { MedTrackMainScreen } from "./screens/MainScreen";
import { Container } from "./styles";

function MedTrack(props) {
  const {
    isFirstAccess,
    dispatch,
    isBases,
    isR3CM,
    activeMedtrackType,
    isRevision,
    hasDefaultMedtrack,
    hasRevisionMedtrack,
    userMedtrackOptions,
    hasRevisionSchedule,
    hasDefaultSchedule,
  } = props;

  const [isAvailableTimeModalOpen, setIsAvailableTimeModalOpen] =
    useState(false);
  const [isAvailableTimeLoading, setIsAvailableTimeLoading] = useState(false);
  const [availableStudyTime, setAvailableStudyTime] = useState(null);
  const [isGeneratingTodoGroup, setIsGeneratingTodoGroup] = useState(false);
  const [showActiveRevisionModal, setShowActiveRevisionModal] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const today = new Date();
  function handleChangeAvailableTime(e) {
    const availableTimeValue = parseFloat(e.target.value);
    setAvailableStudyTime((prevState) => ({
      ...prevState,
      timeAvailable: availableTimeValue,
      scheduleType: isRevision ? "REVIEW" : "DEFAULT",
    }));
  }

  function shouldShowOnboardingModal() {
    const onboardingStartDate = new Date(2024, 6, 30);
    const isOnOrAfterStartDate = today >= onboardingStartDate;

    if (
      isOnOrAfterStartDate &&
      hasDefaultSchedule &&
      !isRevision &&
      hasRevisionMedtrack
    ) {
      setShowOnboardingModal(true);
    } else {
      setShowActiveRevisionModal(false);
    }
  }

  async function getLastUserAction() {
    const response = await medtrackApi.get(
      "/user-activity/last?action=DONT_SHOW_ONBOARDING_REVISION_MODAL"
    );

    return response;
  }

  async function onGetLastUserAction() {
    const onboardingStartDate = new Date(2024, 6, 30);
    const isOnOrAfterStartDate = today >= onboardingStartDate;

    try {
      const response = await getLastUserAction(
        "DONT_SHOW_ONBOARDING_REVISION_MODAL"
      );
      const { action } = response.body;

      if (action) {
        setShowOnboardingModal(false);
      } else {
        setShowOnboardingModal(true);
      }
    } catch (e) {
      if (
        isOnOrAfterStartDate &&
        hasDefaultSchedule &&
        !isRevision &&
        hasRevisionMedtrack
      ) {
        setShowOnboardingModal(true);
      }
      console.error(e);
    }
  }

  useEffect(() => {
    onGetLastUserAction();
  }, [hasDefaultSchedule]);

  async function handleUpdateAvailableTime(fromModal = false) {
    setIsAvailableTimeLoading(true);

    try {
      const { body } = await medtrackApi.post("/user/time-available", {
        timeAvailable: availableStudyTime?.timeAvailable ?? 5,
        scheduleType: isRevision ? "REVIEW" : "DEFAULT",
      });

      setAvailableStudyTime(body);
      setShowActiveRevisionModal(false);

      if (fromModal) {
        toast.success("Disponibilidade de tempo ajustada com sucesso.");
        setIsAvailableTimeLoading(true);
        setIsAvailableTimeModalOpen(false);
      }
    } catch (err) {
      console.error(err);
      alert("Ocorreu um erro ao atualizar o tempo disponível");
      setIsAvailableTimeModalOpen(false);
      setIsAvailableTimeLoading(false);
    }
    setIsAvailableTimeLoading(false);
  }

  async function handleGetAvailableTime() {
    try {
      const { body } = await medtrackApi.get("/time-available/current");

      setAvailableStudyTime(body);
    } catch (err) {
      console.error(err);
      alert("Ocorreu um erro ao buscar o tempo disponível");
    }
  }

  async function handleSaveBigArea(selectedBigArea) {
    await medtrackApi.put("/big-area-priority", {
      bigAreaId: selectedBigArea?.id,
    });
  }

  async function handleNextStep(selectedBigArea) {
    try {
      setIsGeneratingTodoGroup(true);
      if (isFirstAccess) {
        await medtrackApi.post("/user/create");
      }

      await handleUpdateAvailableTime();
      hasMedtrackSchedule(dispatch);

      if (Boolean(selectedBigArea)) {
        handleSaveBigArea(selectedBigArea);
      }

      allowUserAccess(dispatch);
      setIsGeneratingTodoGroup(false);
    } catch (error) {
      console.error(error);
      alert("Ocorreu um erro ao criar o usuário");
    }
  }

  function firstAccessConditionals() {
    if (isRevision && !hasRevisionSchedule && !isGeneratingTodoGroup) {
      return true;
    }

    if (!isRevision && !hasDefaultSchedule && !isGeneratingTodoGroup) {
      return true;
    }

    return isFirstAccess;
  }

  return (
    <MedTrackProvider>
      <Container>
        <GoalsModal />

        <CheckIfIsLoading
          isLoading={isGeneratingTodoGroup}
          placeholder={<Skeleton />}
        >
          {firstAccessConditionals() ? (
            <FirstAccessScreen
              isRevision={isRevision}
              isBases={isBases}
              availableStudyTime={availableStudyTime?.timeAvailable ?? 5}
              onChangeAvailableStudyTime={handleChangeAvailableTime}
              onNextStep={handleNextStep}
              activeMedtrackType={activeMedtrackType}
              isFirstAccess={isFirstAccess}
            />
          ) : (
            <MedTrackMainScreen
              isRevision={isRevision}
              isR3CM={isR3CM}
              isBases={isBases}
              availableStudyTime={availableStudyTime}
              onChangeAvailableStudyTime={handleChangeAvailableTime}
              onUpdateAvailableTime={handleUpdateAvailableTime}
              onGetAvailableTime={handleGetAvailableTime}
              isAvailableTimeModalOpen={isAvailableTimeModalOpen}
              setIsAvailableTimeModalOpen={setIsAvailableTimeModalOpen}
              isAvailableTimeLoading={isAvailableTimeLoading}
              hasDefaultMedtrack={hasDefaultMedtrack}
              hasRevisionMedtrack={hasRevisionMedtrack}
              medtrackType={activeMedtrackType}
              userMedtrackOptions={userMedtrackOptions}
              setShowActiveRevisionModal={setShowActiveRevisionModal}
              showActiveRevisionModal={showActiveRevisionModal}
              isFirstAccess={isFirstAccess}
              showOnboardingModal={showOnboardingModal}
            />
          )}
        </CheckIfIsLoading>
      </Container>

      {showOnboardingModal && (
        <OnboardingRevisionModal
          onClose={() => setShowOnboardingModal(false)}
        />
      )}
    </MedTrackProvider>
  );
}

function mapStateToProps(state) {
  const {
    isFirstAccess,
    activeMedtrackType,
    hasDefaultMedtrack,
    hasRevisionMedtrack,
    hasRevisionSchedule,
    hasDefaultSchedule,
    userMedtrackOptions,
    isBases,
    isR3CM,
    isRevision,
  } = state.isMedTrackFirstAccess;

  return {
    isFirstAccess,
    isBases,
    isRevision,
    isR3CM,
    activeMedtrackType,
    hasDefaultMedtrack,
    hasRevisionMedtrack,
    hasRevisionSchedule,
    hasDefaultSchedule,
    userMedtrackOptions,
  };
}

export default connect(mapStateToProps)(MedTrack);
