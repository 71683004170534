import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { IoArrowForward, IoClose } from "react-icons/io5";
import { connect } from "react-redux";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";
import { colors } from "../../../components/library/theme";
import { getClassrooms } from "../../../selectors/classroomSelectors";
import { getCurrentUser } from "../../../selectors/userSelectors";
import {
  Button,
  DialogContent,
  DialogOverlay,
  Image,
  TitleWrapper,
} from "./styles";

function InsufficientMedcoinModal({
  isOpen,
  dispatch,
  guruUrl,
  firstClassroom,
  completeGuruUrl,
}) {
  function redirect() {
    if (guruUrl) {
      trackClickEvents({
        eventName: "Clique em Matricule-se Já",
        content: {
          Local: "Modal de medcoins insuficientes",
          UrlGuru: completeGuruUrl,
          Error: false,
        },
      });
      window.open(completeGuruUrl, "_self");
    } else {
      trackClickEvents({
        eventName: "Clique em Matricule-se Já",
        content: {
          Local: "Modal de medcoins insuficientes",
          MessageError: `O Curso ${firstClassroom.name} com o classroom id ${firstClassroom.id} não possui o link do guru`,
          Error: true,
        },
      });
      throw new Error(
        `O Curso ${firstClassroom.name} com o classroom id ${firstClassroom.id} não possui o link do guru`
      );
    }
  }

  function handleModal() {
    if (isOpen) {
      return dispatch({ type: "HIDE_INSUFFICIENT_MEDCOINS_MODAL" });
    }

    dispatch({ type: "SHOW_INSUFFICIENT_MEDCOINS_MODAL" });
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleModal}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <TitleWrapper>
              <h2>Medcoins insuficientes</h2>
              <Dialog.Close>
                <IoClose size={32} fill={colors.interface.grey400} />
              </Dialog.Close>
            </TitleWrapper>
            <Image />
            <p>
              Você não possui Medcoins suficientes. Faça sua matrícula agora e
              tenha acesso ilimitado a plataforma.
            </p>

            <Button onClick={redirect} fullSized>
              Faça sua Matrícula <IoArrowForward size={24} />
            </Button>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

function mapStateToProps(state) {
  const user = getCurrentUser(state);
  const classrooms = getClassrooms(state);
  const firstClassroom = Object.values(classrooms)[0];
  const guruUrl = firstClassroom?.guruLink;
  const completeGuruUrl =
    guruUrl + `?name=${user?.name}&email=${user?.email}&phone=${user?.phone}`;
  return {
    user,
    guruUrl,
    completeGuruUrl,
    firstClassroom,
  };
}

export default connect(mapStateToProps)(InsufficientMedcoinModal);
