import React, { useState } from "react";
import { IoArrowForward, IoInformationCircle } from "react-icons/io5";
import { BigAreaDropdown } from "../../../components/BigAreaDropdown";
import { MedTrackTooltip } from "../../../components/MedTrackTooltip";
import { Slider } from "../../../components/Slider";
import {
  BigAreaTitle,
  BigAreaTitleWrapper,
  Button,
  Container,
  ContainerWeeklyStudyTimeScreen,
  ContentWrapper,
  Hr,
  Instructions,
  Subtitle,
  Wrapper,
} from "./styles";
import { Illustration } from "./MedtrackIllustration";
import { Description } from "./Description";
import { MedtrackTitle } from "./MedtrackTitle";
import { useMedTrack } from "../../../hooks/useMedTrack";

export function WeeklyStudyTimeScreen({
  onNextStep,
  availableStudyTime,
  onChangeAvailableStudyTime,
  isBases,
  isRevision,
  isFirstAccess,
}) {
  const [selectedBigArea, setSelectedBigArea] = useState();
  async function handleNextStep(selectedBigArea) {
    onNextStep(selectedBigArea);
  }

  return (
    <ContainerWeeklyStudyTimeScreen>
      <Container>
        <Wrapper>
          <Illustration isRevision={isRevision} />

          <ContentWrapper>
            <MedtrackTitle isRevision={isRevision} />
            <Description isRevision={isRevision} />
          </ContentWrapper>

          <Hr />
          {isBases && (
            <>
              <Subtitle>Defina os detalhes iniciais</Subtitle>

              <ContentWrapper>
                <BigAreaTitleWrapper>
                  <BigAreaTitle>Rodízio de estudos:</BigAreaTitle>

                  <MedTrackTooltip
                    width="254px"
                    variant="titleInfoBigArea"
                    text="O Medtrack se adapta ao seu rodízio atual. Defina a grande-área que está rodando nesse momento e suas tarefas de estudos serão ajustadas automaticamente."
                  >
                    <IoInformationCircle size={20} fill="#C2CED6" />
                  </MedTrackTooltip>
                </BigAreaTitleWrapper>

                <BigAreaDropdown
                  selectedBigArea={selectedBigArea}
                  setSelectedBigArea={setSelectedBigArea}
                  isFirstAccess={isFirstAccess}
                />
              </ContentWrapper>
            </>
          )}

          <ContentWrapper>
            <Instructions>
              Defina sua disponibilidade de horas por semana para estudar:
            </Instructions>

            <Slider
              availableHours={availableStudyTime}
              onChange={onChangeAvailableStudyTime}
            />
          </ContentWrapper>

          <Button
            disabled={isBases && !selectedBigArea}
            onClick={() => handleNextStep(selectedBigArea)}
          >
            Começar os estudos <IoArrowForward fill="#fff" />
          </Button>
        </Wrapper>
      </Container>
    </ContainerWeeklyStudyTimeScreen>
  );
}
