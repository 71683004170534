import React from "react";
import { LiaCoinsSolid } from "react-icons/lia";
import { connect } from "react-redux";
import { getCurrentUser } from "../../../selectors/userSelectors";
import { Bar, Container, Progress, Text } from "./styles";

function MedcoinSideBar(props) {
  const { onMedcoinClick, user } = props;
  const value = user.medcoin;

  return (
    <Container value={value} onClick={onMedcoinClick}>
      <Text value={value}>
        <LiaCoinsSolid size={25} /> {value} Medcoins
      </Text>
      <Progress>
        <Bar value={value} />
      </Progress>
    </Container>
  );
}

function mapStateToProps(state, ownProps) {
  const user = getCurrentUser(state);
  return { user, ...ownProps };
}

export default connect(mapStateToProps)(MedcoinSideBar);
