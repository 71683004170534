import React from "react";
import Button from "../library/Button";
import Link from "../library/Link";
import { css } from "glamor";
import { spacing, uiColors } from "../library/theme";
import { trackCreateNewMockTemplateEvent } from "../../../services/mixpanel/events/trackCreateNewMockTemplateEvent";
import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import { onlyFrontDicountMedcoin } from "../../processes/medcoinProcess";

const container = css({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: spacing.s,
  margin: `${spacing.m} -${spacing.m} 0 -${spacing.m}`,
  borderTop: `1px solid ${uiColors.border}`,
  padding: `${spacing.m} ${spacing.s} 0 0`,
  "@media(max-width: 767px)": {
    textAlign: "center",
    justifyContent: "center",
    margin: `${spacing.m} -1rem 0 -1rem`
  }
});
const actionButtons = css({ display: "flex", gap: spacing.s });
const questions = css({ minWidth: 350, paddingLeft: spacing.s });
const hideStyle = { display: "none" };

function MockModalFooter(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    availableQuestionsCount,
    loading,
    showCount,
    user,
    isFrial,
    dispatch,
    hasContent
  } = props;

  const shouldBeDisabled = pristine && !hasContent;

  function getInputTags(elementId) {
    const anchorElements = document
      .getElementById(elementId)
      .getElementsByTagName("a");
    const totalOfAnchorElements = anchorElements.length - 1;
    let tagsArray = [];

    for (let index = 0; index <= totalOfAnchorElements; index++) {
      const currentTag = anchorElements[index];

      tagsArray.push(currentTag.textContent);
    }

    return tagsArray;
  }

  function handleButtonClick(e) {
    const menuMockType = document.getElementsByClassName("active item")[0].text;
    if (menuMockType === "Simulado Personalizado") {
      const [
        mockName,
        ,
        ,
        ,
        ,
        ,
        isOnlyUnansweredQuestions,
        isOnlyIncorrectQuestions,
        isExamSimulated,
        mockQuestionsType
      ] = document.getElementsByClassName("ui form")[0];

      const specialtiesArray = getInputTags("specialitiesIdIn");
      const subjectsArray = getInputTags("subjectsIdIn");
      const statesArray = getInputTags("organizersStateIdIn");
      const organizersArray = getInputTags("organizersIdIn");
      const yearsArray = getInputTags("yearMatchesAny");
      const examTypeArray = getInputTags("examTypeExamTypeCategoryIdIn");

      trackCreateNewMockTemplateEvent({
        eventType: "createMock",
        formCreateMock: {
          mockType: menuMockType,
          mockName: mockName.value,
          specialty: specialtiesArray,
          subject: subjectsArray,
          state: statesArray,
          organizers: organizersArray,
          years: yearsArray,
          examType: examTypeArray,
          isOnlyUnansweredQuestions: isOnlyUnansweredQuestions.value,
          isOnlyIncorrectQuestions: isOnlyIncorrectQuestions.value,
          isExamSimulated: isExamSimulated.value,
          mockQuestionsType: mockQuestionsType.value
        },
        isFrialUser: isFrial
      });
    } else if (menuMockType === "Prova na Íntegra") {
      const [state] = document
        .getElementById("stateIdEq")
        .getElementsByTagName("div");
      const [organizer] = document
        .getElementById("organizerIdEq")
        .getElementsByTagName("div");
      const [mock_template] = document
        .getElementById("mockTemplateId")
        .getElementsByTagName("div");
      const [year] = document
        .getElementById("yearEq")
        .getElementsByTagName("div");

      trackCreateNewMockTemplateEvent({
        eventType: "createMock",
        formCreateMock: {
          mockType: menuMockType,
          state: state.textContent,
          organizer: organizer.textContent,
          year: year.textContent,
          mock_template: mock_template.textContent
        },
        isFrialUser: isFrial
      });
    }

    handleSubmit(e).then((error) => {
      if (!error && isFrial) {
        onlyFrontDicountMedcoin(dispatch, user);
      }
    });
  }

  return (
    <div className={container}>
      <div className={questions}>
        <p className={css(!showCount && hideStyle)}>
          {loading
            ? "Carregando..."
            : availableQuestionsCount !== (null || undefined) &&
              `${availableQuestionsCount} questões disponíveis`}
        </p>
      </div>
      <div className={actionButtons}>
        <Link to="/mocks" component={Button} type="button">
          Cancelar
        </Link>
        <Button
          type="submit"
          onClick={handleButtonClick}
          disabled={shouldBeDisabled || submitting}
          primary
        >
          Criar Simulado
        </Button>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const user = getCurrentUser(state);
  const isFrial = state?.isFrialUser;

  return { user, isFrial, ...ownProps };
}

export default connect(mapStateToProps)(MockModalFooter);
