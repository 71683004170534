import React from "react";
import { colors } from "../../../components/library/theme";
import { LiaCoinsSolid } from "react-icons/lia";
import { Tooltip } from "./tooltip";
import { MedcoinStamp } from "./styles";
import { connect } from "react-redux";

function MedcoinIndicator({
  children,
  variant,
  isFrialUser,
  isLevelPurchased = false
}) {
  const medcoinIconColor = isLevelPurchased
    ? colors.interface.white000
    : colors.brand.pure;

  return isFrialUser ? (
    <MedcoinStamp
      variant={variant}
      onMouseOver={(e) => Tooltip(e, children)}
      isLevelPurchased={isLevelPurchased}
    >
      <LiaCoinsSolid fill={medcoinIconColor} />
    </MedcoinStamp>
  ) : (
    <></>
  );
}

function mapStateToProps(state) {
  const isFrialUser = state.isFrialUser;

  return {
    isFrialUser
  };
}

export default connect(mapStateToProps)(MedcoinIndicator);
