import React from "react";
import { StyledTooltip } from "./styles";

export function TooltipContent({ children }) {
  return (
    <StyledTooltip>
      <svg
        width="89"
        height="9"
        viewBox="0 0 89 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.0858 6.58579L37.3787 0.87868C36.8161 0.316071 36.053 0 35.2574 0H53.7426C52.947 0 52.1839 0.31607 51.6213 0.878679L45.9142 6.58579C45.1332 7.36684 43.8668 7.36684 43.0858 6.58579Z"
          fill="#EDF0F2"
        />
        <path
          d="M34.5 0.5H34.8431C35.904 0.5 36.9214 0.921427 37.6716 1.67157L42.3787 6.37868C43.5503 7.55025 45.4497 7.55025 46.6213 6.37868L51.3284 1.67157C52.0786 0.921426 53.096 0.5 54.1569 0.5H54.5"
          stroke="#C2CED6"
        />
      </svg>
      {children}
    </StyledTooltip>
  );
}
