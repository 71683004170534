import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { IoArrowForward } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { ProgressBar } from './ProgressBar';
import { CompleteProgressIllustration } from './assets/completeProgressIllustration';
import { HalfProgressIllustration } from './assets/halfProgressIllustration';
import { InProgressIllustration } from './assets/inProgressIllustration';
import { StartProgressIllustration } from './assets/startProgressIllustration';
import { ordinalNumbersEnum } from './ordinalNumbers.enum';
import {
  Button,
  Checkbox,
  Container,
  ContainerStartMedtrackScreen,
  ContentWrapper,
  Hr,
  Info,
  Instructions,
  ProgressContainer,
  ProgressContentWrapper,
  ProgressText,
  QuestionsContainer,
  Title,
  Wrapper,
} from './styles';

function getInfoByProgress(exams) {
  const infos = {
    START_PROGRESS: {
      title: 'Quer começar a usar o Medtrack?',
      Illustration: () => <StartProgressIllustration />,
      buttonText: 'Começar desafio',
    },
    IN_PROGRESS: {
      title: 'Você já progrediu um pouco. Continue assim!',
      Illustration: () => <InProgressIllustration />,
      buttonText: 'Continuar desafio',
    },
    HALF_PROGRESS: {
      title: 'Você avançou bastante. Sabíamos que ia conseguir!',
      Illustration: () => <HalfProgressIllustration />,
      buttonText: 'Continuar desafio',
    },
    COMPLETE_PROGRESS: {
      title: 'Isso aí! Falta pouco para começar a usar o Medtrack.',
      Illustration: () => <CompleteProgressIllustration />,
      buttonText: 'Continuar desafio',
    },
  };

  if (!exams.some((exam) => exam.progress > 0)) {
    return infos.START_PROGRESS;
  }

  if (exams.every((exam) => exam.progress === 1)) {
    return infos.COMPLETE_PROGRESS;
  }

  if (exams.some((exam) => exam.progress === 1)) {
    return infos.HALF_PROGRESS;
  }
  return infos.IN_PROGRESS;
}

function parseMedtrackExams(response) {
  const medtrackExams = response.map((medtrackExam) => {
    return {
      id: medtrackExam.id,
      name: medtrackExam.name,
      questionsCount: medtrackExam.meta.question_count,
      doneQuestionsCount: medtrackExam.meta.done_questions_count,
      progress: medtrackExam.progress,
    };
  });

  return {
    totalOfQuestions: getTotalOfQuestions(response),
    exams: medtrackExams,
  };
}

function getTotalOfQuestions(response) {
  return response.reduce((acc, curr) => {
    return acc + curr.meta.question_count;
  }, 0);
}

function getInfoText(medtrackExams) {
  const arrayTotalQuestionsText = medtrackExams.exams
    .map((exam, index) => {
      const examPosition = index + 1;
      const isTheLastExam = examPosition === medtrackExams.exams.length;
      const isThePenultimateExam =
        examPosition === medtrackExams.exams.length - 1;

      // TODO: refatorar para remover dangerouslySetInnerHTML do componente <Info />
      const questionText = `<strong style="font-weight: 600">${
        exam.questionsCount
      } questões</strong> na ${ordinalNumbersEnum[
        examPosition
      ].toLowerCase()} fase`;

      return isTheLastExam
        ? `e outras ${questionText}`
        : `${isThePenultimateExam ? questionText : `${questionText},`} `;
    })
    .join('');

  return `
  Para que o Medtrack te conheça e possa te orientar com muita assertividade, precisa que você passe por um desafio: acesse a página de simulados e realize ${arrayTotalQuestionsText}. Seu desempenho, após responder as <strong style="font-weight: 600">${medtrackExams?.totalOfQuestions} questões</strong>, vai ajudar o Medtrack a definir as melhores rotas para seus estudos de forma mais inteligente. Vamos começar?
  `;
}

export function StartMedtrackScreen({ medtrackExamsResponse }) {
  const history = useHistory();
  const medtrackExams = parseMedtrackExams(medtrackExamsResponse);
  const info = getInfoByProgress(medtrackExams.exams);

  const infoText = getInfoText(medtrackExams, medtrackExamsResponse);

  function handleKeepChallenge() {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'redirectToMedtrackMocks',
        })
      );
    } else {
      history.push({
        pathname: '/mocks',
        search: '?tab=medtrack&isMedtrackOrigin=true',
      });
    }
  }

  return (
    <ContainerStartMedtrackScreen>
      <Container>
        <Wrapper>
          {info?.Illustration({}) ?? 'Carregando...'}
          <ContentWrapper>
            <Title>{info?.title ?? 'Carregando...'}</Title>
            <Info
              dangerouslySetInnerHTML={{
                __html: infoText,
              }}
            ></Info>
          </ContentWrapper>
          <Hr />

          <Instructions>Seu progresso:</Instructions>

          <ProgressContentWrapper>
            {medtrackExams.exams.map((medtrackExam, index) => {
              const progressBarWidth =
                (medtrackExam.questionsCount / medtrackExams.totalOfQuestions) *
                100;
              const isCompleted = medtrackExam.progress === 1;
              const ordinalNumberText = ordinalNumbersEnum[index + 1];

              const isSecondPhase = index === 1;

              return (
                <ProgressContainer
                  width={`${progressBarWidth}%`}
                  key={medtrackExam.id}
                >
                  <ProgressBar
                    progress={medtrackExam.progress * 100}
                    isSecondPhase={isSecondPhase}
                  />
                  <QuestionsContainer>
                    {isCompleted && (
                      <Checkbox>
                        <IoMdCheckmark fill="#fff" />
                      </Checkbox>
                    )}
                    <ProgressText>{`${ordinalNumberText} fase`}</ProgressText>
                  </QuestionsContainer>
                </ProgressContainer>
              );
            })}
          </ProgressContentWrapper>

          <Hr />

          <Button onClick={handleKeepChallenge}>
            {info?.buttonText ?? 'Carregando...'} <IoArrowForward fill="#fff" />
          </Button>
        </Wrapper>
      </Container>
    </ContainerStartMedtrackScreen>
  );
}
