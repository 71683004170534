import React from "react";
import { LiaCoinsSolid } from "react-icons/lia";
import { colors } from "../../../../components/library/theme";
import { Container, Title, Value, ValueWrapper } from "./styles";

export function Card({ title, value }) {
  return (
    <Container>
      <Title>{title}</Title>
      <ValueWrapper>
        <Value>{value}</Value>
        <LiaCoinsSolid size={42} fill={colors.brand.pure} />
      </ValueWrapper>
    </Container>
  );
}
