import styled, { css } from 'styled-components';
import { colors } from '../../library/theme';

const statusColors = {
  pending: css`
    background-color: ${colors.status.red000};
    color: ${colors.status.red300};
  `,
  completed: css`
    background-color: ${colors.status.blue100};
    color: ${colors.status.blue400};
  `,
  onGoing: css`
    background-color: ${colors.status.yellow000};
    color: ${colors.status.yellow200};
  `,
};

export const Container = styled.div`
  width: 100%;
  min-width: 114px;
  border-radius: 70px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px){
    max-width: 194px !important;
  }

  ${({ hasMedcoin }) => !hasMedcoin && `margin: auto;`}
  ${(props) => statusColors[props.mode]}
`;

export const MedcoinMobile = styled.div`
  display: none !important;
  
  @media (max-width: 768px) {
    display: block !important;
  }
`