import React from "react";
import { IoArrowForward, IoCheckmark } from "react-icons/io5";
import { ArrowIcon, CompletedTaskButton, ButtonSpinner } from "./styles";

export function TodoButton({ isCompleted, isLoading = false }) {
  if (isCompleted) {
    return (
      <CompletedTaskButton>
        <IoCheckmark stroke="#fff" size={18} />
      </CompletedTaskButton>
    );
  }

  return (
    <>
      {isLoading ?
        <ButtonSpinner />
        :
        <ArrowIcon>
          <IoArrowForward stroke="#fff" size={18} />
        </ArrowIcon>
      }
    </>
  );
}
