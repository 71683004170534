import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

const getVariant = (variant, isBrowserSupported, isBases) => {
  switch (variant) {
    case "todoLocked":
      return `
      inset:0;
      left: 38%;
      top: ${isBases ? "calc(-60% + -8px)" : "-100%"};
      bottom: 0;
      right: 0;
      @media(max-width: 640px){
        margin-left: auto;
        width: 90vw;
        top: ${!isBrowserSupported ? "-16vw" : "calc(-55% + -6px)"};
        right: ${!isBrowserSupported ? "51vw" : "calc(-3% + -8px)"};
      }

      ${!isBrowserSupported && "transform: translate(150%, -150%);"}
      `;
    case "todoProgress":
      return `
      left: calc(100% + 8px);
      top: 50%;
      @media (max-width: 991px) {
        display: none !important;
      }
      `;
    case "todoItemProgress":
      return `
        left: calc(100% + 8px);
        top: 50%;
        @media (max-width: 1200px) {
          display: none !important;
        }
        `;
    case "titleInfoBigArea":
      return `
      left: calc(100% + 8px);
      @media (max-width: 991px) {
        left: -400%;
        top: 500%;    
        ::after {
          top: 64px;
          left: -5px;
          transform: rotate(45deg);
        }
      }
      @media (max-width: 640px){
        left: -450%;
        top: 80px;
        width: 70vw;
        ::after {
          top: -5px !important;
          left: 94px !important;
        }
      }
      `;
    case "titleInfo":
      return `
      left: calc(100% + 8px);
      @media (max-width: 991px) {
        left: -400%;
        top: 500%;    
        ::after {
          top: -5px !important;
          left: 34% !important;
          transform: rotate(135deg) !important;
        }
      }
      @media (max-width: 640px){
        left: -450%;
        top: 80px;
        width: 90vw;
        ::after {
          top: -5px !important;
          left: 94px !important;
        }
      }
      `;
    case "recalculateButton":
      return `
      right: calc(100% + 8px);
      top: 25%;
      @media (max-width: 991px) {
        left: -30%;
        top: 300%;
        ::after {
          top: -5px !important;
          left: 50%;
          transform: rotate(135deg) !important;
        }
      }
      @media (max-width: 640px){
        left: 0;
        right: -37%;
        top: 98px;
        width: 90vw;
        margin-left: auto;
        ::after {
          top: -5px !important;
          right: 120px !important;
          margin-left: auto;
        }
      }
      `;
    case "subjectModal":
      return `
          font-size: 12px;
          line-height: 18px;
          left: calc(100% + 8px);
          @media (max-width: 991px) {
            left: -400%;
            top: 500%;    
            ::after {
              top: -5px !important;
              left: 54% !important;
            }
          }
          @media (max-width: 991px){
            left:-310px;
            top: 46px;
            width: 580px;
            ::after{
              left: -279px;  
              transform: rotate(135deg);
            }
          }
          @media (max-width: 640px){
            left: -279px;
            top: 51px;
            width: 85vw;
            ::after {
              top: -5px !important;
              left: 284px !important;
            }
          }
          `;
    case "bigArea":
      return `
          left: -305%;
          top: 395%;    
          ::after {
            top: -5px !important;
            left: 34% !important;
            transform: rotate(135deg) !important;
          }
          @media (max-width: 991px){
            left: -232px;
            top: 62px;
            width: 348px;
            ::after{
              left: 236px !important;
            }
          }
        `;
    case "revisionMode":
      return `
          width: 217px;
          top: 52%;
          left: 190%;
          ::after {
            top: 23px !important;
            left: -2.5% !important;
            transform: rotate(45deg) !important;
          }
          @media (max-width: 740px){
            top: 59px !important;
            left: -97px !important;
            ::after {
              top: -6px !important;
              left: 101px !important;
              transform: rotate(135deg) !important;
            }
          }
        `;
    default:
      break;
  }
};

const getArrowSide = (side) => {
  switch (side) {
    case "top":
      return `
        transform: rotate(-45deg);
        bottom: -5px;
        left: 50%;
        `;
    case "right":
      return `
          transform: rotate(45deg);
          left: -5px;
          bottom: 38%;
          `;
    case "left":
      return `
          transform: rotate(-135deg);
          right: -5px;
          bottom: 38%;
          `;
    default:
      break;
  }
};

export const Tooltip = styled.div`
  color: ${colors.medtrack.grey500};
  display: none;
  width: ${({ width }) => width};
  height: fit-content;
  font-size: ${({ variant }) =>
    (variant === "titleInfo") | "bigArea" ? "14px" : "12px"};
  font-weight: 400;
  line-height: 21px;
  border-radius: 4px;
  border: solid 1px #c2ced6;
  position: absolute;
  padding: ${({ hasTitle }) => (hasTitle ? "16px" : "8px")};
  z-index: 999;
  background-color: #edf0f2;
  text-align: left;
  transform: translateY(-50%);

  ::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    border: 1px solid #c2ced6;
    background-color: #edf0f2;
    border-top-left-radius: 2px;
    position: absolute;
    border-width: 0 0 1px 1px;
    top: unset;
    right: unset;
    ${({ side }) => getArrowSide(side)}
  }

  ${({ variant, isBrowserSupported, isBases }) =>
    getVariant(variant, isBrowserSupported, isBases)}
`;

export const TooltipContainer = styled.span`
  position: relative;
  display: ${({ side }) => (side === "top" ? "unset" : "flex")};

  ${Tooltip} {
    display: ${({ forceMount }) => (forceMount ? "block" : "none")};
  }

  :hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

export const TooltipTitle = styled.p`
  color: ${colors.interface.grey400};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;
