import React from "react";
import {
  IoCreateOutline,
  IoDocumentTextOutline,
  IoStarOutline
} from "react-icons/io5";
import Card from "./card";
import { CardsWrapper, CardText, Title } from "./styles";
import MedcoinIndicator from "../../../componentsV2/medcoins/medcoinIndicator"

export function Shortcuts() {
  return (
    <section>
      <Title>Reforce seu aprendizado!</Title>
      <CardsWrapper>
        <Card href="/mocks/new" Icon={IoCreateOutline}>
          <CardText>Crie um simulado Personalizado</CardText>
        </Card>
        <Card href="mocks/new?examType=integra" Icon={IoDocumentTextOutline}>
          <CardText>Crie uma Prova na Íntegra</CardText>
        </Card>
        <Card href="mocks?type=smart" Icon={IoStarOutline}>
          <CardText>Crie um simulado Inteligente</CardText>
        </Card>
      </CardsWrapper>
    </section>
  );
}
