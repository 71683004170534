import * as Dialog from '@radix-ui/react-dialog';
import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { colors } from '../../../../components/library/theme';
import { Infobox } from '../Infobox';
import { Slider } from '../Slider';
import {
  ButtonContainer,
  CloseButton,
  DialogContent,
  DialogOverlay,
  Hr,
  ModalHeader,
  SliderWrapper,
  StyledEMRButton,
  StyledSpinner,
  TextWrapper,
} from './styles';

export function TimeAvailability({
  availableStudyTime,
  onChangeAvailableTime,
  onGetAvailableTime,
  onUpdateAvailableTime,
  isAvailableTimeModalOpen,
  setIsAvailableTimeModalOpen,
  isAvailableTimeLoading,
}) {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const isLoading = isAvailableTimeLoading || isLoadingData;
  const hasUpdatedAt = availableStudyTime?.updatedAt;
  const hasNextDateToUpdate = availableStudyTime?.nextDateToUpdate;

  const updatedAt =
    hasUpdatedAt &&
    new Date(availableStudyTime.updatedAt).toLocaleDateString('pt-br', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  const nextDateToUpdate =
    hasNextDateToUpdate &&
    new Date(availableStudyTime.nextDateToUpdate).toLocaleDateString('pt-br', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

  useEffect(() => {
    if (!availableStudyTime) {
      onGetAvailableTime();
    }
    setIsLoadingData(false);
  }, []);

  return (
    <Dialog.Root
      open={isAvailableTimeModalOpen}
      onOpenChange={setIsAvailableTimeModalOpen}
    >
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <TextWrapper>
              <ModalHeader>
                <h2>Ajuste sua disponibilidade de tempo</h2>

                <Dialog.Close asChild>
                  <CloseButton>
                    <IoClose size={24} color={colors.interface.grey300} />
                  </CloseButton>
                </Dialog.Close>
              </ModalHeader>
              <p>
                Fique a vontade para ajustar seu tempo de estudos conforme a
                disponibilidade da sua semana. Lembre-se de manter sempre
                atualizado para que você esteja sempre no caminho certo.
              </p>
            </TextWrapper>

            <Hr />

            <SliderWrapper>
              <p>
                Defina sua disponibilidade de horas por semana para estudar:
              </p>
              <Slider
                defaultValue={availableStudyTime?.timeAvailable ?? 5}
                availableHours={availableStudyTime?.timeAvailable ?? 5}
                onChange={onChangeAvailableTime}
              />

              <span>
                {updatedAt && `Atualizado dia ${updatedAt}`}
                {nextDateToUpdate &&
                  ` - Válido a partir
                  de ${nextDateToUpdate}`}
              </span>
            </SliderWrapper>

            <Infobox variant="lightGrey">
              Ao definir uma nova disponibilidade de horas de estudos semanal
              suas tarefas serão automaticamente ajustadas conforme sua
              disponibilidade a partir da semana seguinte.
            </Infobox>

            <Hr />

            <ButtonContainer>
              <Dialog.Close asChild>
                <StyledEMRButton mode="cancel" fullSized>
                  Cancelar
                </StyledEMRButton>
              </Dialog.Close>

              <StyledEMRButton
                mode="standard"
                fullSized
                disabled={isLoading}
                onClick={onUpdateAvailableTime}
              >
                {isLoading ? (
                  <StyledSpinner inline size="small" />
                ) : (
                  'Salvar alterações'
                )}
              </StyledEMRButton>
            </ButtonContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
