import React, { useEffect, useRef } from "react";
import { css } from "glamor";
import {
  colors,
  spacing,
  styles,
  typographyColors,
  uiColors
} from "../library/theme";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdRadioButtonUnchecked } from "react-icons/md";
import { trackAccessToLessonEvent } from "../../../services/mixpanel/events/trackAccessToLessonEvent";
import { useHistory } from "react-router-dom";
import { getPurchasedLevels } from "../../processes/levelProcesses";
import { discountMedcoinWithLevel } from "../../processes/medcoinProcess";

const container = css(styles.transition, styles.roundedCorners, {
  display: "flex",
  alignItems: "center",
  width: "100%",
  border: 0,
  background: "transparent",
  flex: 1,
  padding: `${spacing.s} ${spacing.s}`,
  cursor: "pointer",
  justifyContent: "space-between",
  "& + &": {
    borderTop: `1px solid ${uiColors.backgroundDark}20`
  },
  ":hover": {
    backgroundColor: `${uiColors.backgroundDark}20`
  },
  "& > svg": {
    marginLeft: spacing.s,
    flexShrink: 0
  }
});
const activeStyle = css({
  backgroundColor: uiColors.backgroundDark,
  color: `${typographyColors.inverseBody} !important`,
  ":hover": {
    backgroundColor: uiColors.backgroundDark
  }
});
const textWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
  flexWrap: "wrap"
});
const warningStyle = css(styles.transition, {
  color: uiColors.background,
  minWidth: spacing.xxl,
  marginLeft: "auto"
});
const compactWarning = css({
  minWidth: spacing.xxl,
  marginLeft: "auto"
});

export default function LessonListItem(props) {
  const history = useHistory();
  const {
    dispatch,
    lesson,
    isFrialUser,
    currentClassroomId,
    compact,
    isWatched,
    isRatingPending,
    level,
    userId,
    levelGroupPosition,
    isLevelPurchased
  } = props;
  const lessonItemRef = useRef(null);
  const MEDCOIN_COST = 10;
  const active = props.currentLessonId === lesson.id;
  const warningClass = css(
    warningStyle,
    compact && compactWarning,
    active && activeStyle
  );

  async function descountMedcoin() {
    if (!isLevelPurchased) {
      try {
        await discountMedcoinWithLevel(dispatch, userId, {
          level_id: lesson.levelId,
          medcoin: MEDCOIN_COST
        });
        await getPurchasedLevels(dispatch);
        trackAccess();
        history.push(`/classrooms/${currentClassroomId}/lessons/${lesson.id}`);
      } catch (error) {
        return;
      }
    } else {
      history.push(`/classrooms/${currentClassroomId}/lessons/${lesson.id}`);
    }
  }

  function handleClick() {
    if (isFrialUser && levelGroupPosition > 1) {
      level.enabledToBuy
        ? descountMedcoin()
        : dispatch({ type: "SHOW_ONLY_ENROLLED_STUDENTS_MODAL" });
    } else {
      history.push(`/classrooms/${currentClassroomId}/lessons/${lesson.id}`);
      trackAccess();
    }
  }

  function trackAccess() {
    trackAccessToLessonEvent({
      lessonName: lesson.name,
      lessonId: lesson.id,
      lessonType: lesson.lessonType,
      classroomId: currentClassroomId,
      levelId: lesson.levelId,
      mockTemplateId: lesson.mockTemplateId,
      isFrialUser
    });
  }

  useEffect(() => {
    if (lessonItemRef?.current && active) {
      lessonItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }, [active]);

  return (
    <button
      ref={lessonItemRef}
      className={css(container, active && activeStyle)}
      onClick={handleClick}
    >
      <div className={!compact ? textWrapper : undefined}>
        <p
          className={css(
            active && { color: typographyColors.inverseBody },
            compact && styles.bodyTextSmall
          )}
        >
          {lesson.name}
        </p>
        {lesson.ratingEnabled && isRatingPending && (
          <p className={warningClass.toString()}>Avalie esta aula</p>
        )}
      </div>
      {isWatched ? (
        <AiFillCheckCircle size={22} fill={colors.brand.pure} />
      ) : (
        <MdRadioButtonUnchecked size={22} fill={colors.brand.pure} />
      )}
    </button>
  );
}
