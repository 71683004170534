import React from "react";
import { useCallback } from "react";
import { connect } from "react-redux";
import { signOut } from "../../processes/sessionProcesses";
import { getClassrooms, getMocksEnabled } from "../../selectors/classroomSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import MainMenu from "./MainMenu";

function MainMenuContainer(props) {
  const { dispatch } = props;

  const logoutHandler = useCallback(() => {
    signOut(dispatch);
  }, [dispatch]);

  return <MainMenu logoutHandler={logoutHandler} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { isB2BUserExamInProgress } = state.isB2BUserExamInProgress;
  const currentUser = getCurrentUser(state);
  const isB2BUser = state.userType.isB2B;
  const isFrial = state?.isFrialUser;
  const isFrialRequestLoaded = isFrial !== null;
  const classrooms = getClassrooms(state)
  const firstClassroom = Object.values(classrooms)[0];
  const guruUrl = firstClassroom?.guruLink
  const completeGuruUrl = guruUrl + `?name=${currentUser?.name}&email=${currentUser?.email}&phone=${currentUser?.phone}`
  return {
    insufficientMedcoinsModal: state.insufficientMedcoinsModal,
    currentClassroomId: state.currentClassroomId,
    mocksEnabled: getMocksEnabled(state),
    isB2BUserExamInProgress,
    currentUser,
    isB2BUser,
    medtrackEnabled: state.isMedTrackFirstAccess.medtrackEnabled,
    isFrial,
    isFrialRequestLoaded,
    guruUrl,
    firstClassroom,
    completeGuruUrl,
    classrooms,
    ...ownProps
  };
}

export default connect(mapStateToProps)(MainMenuContainer);
