import * as Dialog from "@radix-ui/react-dialog";

import React, { useCallback, useEffect, useState } from "react";
import { FaChevronLeft, FaRegCheckCircle } from "react-icons/fa";
import { Icon, Sidebar, SidebarPusher } from "semantic-ui-react";
import { spacing, uiColors, utilityColors } from "../library/theme";

import { css, select } from "glamor";
import { makeIsMobile, useScreenWidth } from "../../utils/useScreenWidth";
import { getSearchParams } from "../../utils/getSearchParams";
import AttachmentListContainer from "../attachments/AttachmentListContainer";
import ClassroomContentsContainer from "../classroomContents/ClassroomContentsContainer";
import CommentsContainer from "../comments/CommentsContainer";
import Button from "../library/Button";
import Link from "../library/Link";
import Spinner from "../library/Spinner";
import { MockRevisionReport } from "../mocks/MockRevisionReport";
import TextHighlighter from "../mocks/TextHighlighter";
import { ModalReview } from "../modalReview";
import getUrlParams from "../modalReview/getUrlParams";
import LessonContentContainer from "./LessonContentContainer";
import LessonRatingFormContainer from "./LessonRatingFormContainer";

const rightSidebarStyle = {
  padding: 0,
  boxShadow: `0 2px 5px ${utilityColors.shadow}`,
  backgroundColor: uiColors.fieldBackground,
};
const leftSidebarStyle = {
  padding: 0,
  backgroundColor: uiColors.fieldBackground,
  boxShadow: `0 2px 5px ${utilityColors.shadow}`,
};
const titleWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: spacing.m,
  gap: spacing['s+'],
  "@media(max-width: 1280px)": {
    flexDirection: "column"
  }
});
const titleClass = css({ margin: 0 });
const commentsWrapper = css({
  marginTop: spacing.m,
  paddingTop: spacing.m,
  borderTop: `1px solid ${uiColors.border}`,
});
const titleAndButton = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: spacing.m,
});
const descriptionStyle = { paddingTop: spacing.m };
const buttonsWrapper = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: spacing.s,
  marginTop: spacing.m,
  textAlign: "right",
};
const watchedButtonClass = css({
  height: "40px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const butonsWrappper = css(titleAndButton, {
  padding: 0,
  marginTop: spacing.m,
});
const exportMockClass = css({
  minWidth: 175
});
const contentWrapper = css({
  display: "flex",
});
const mobileActions = css({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: spacing.m,
  "@media(min-width: 990px)": {
    display: "none",
  },
});
const content = {
  height: "calc(100vh - 70px)",
  minHeight: "calc(100vh - 70px)",
  overflowY: "auto",
};
const leftContent = css(content, {
  background: uiColors.fieldBackground,
  maxWidth: "300px",
  width: "30%",
  float: "left",
  flexGrow: 1,
  "@media(max-width: 990px)": {
    display: "none",
  },
});
const rightContent = css(content, {
  display: "grid",
  gridTemplateColumns: "1fr auto",
  flex: 1,
});
const mainContent = css({
  padding: `${spacing.m} ${spacing.m} ${spacing.l} ${spacing.m}`,
});
const computerAnnotationWrapper = css({
  background: uiColors.fieldBackground,
  width: 300,
  maxWidth: 300,
  "@media(max-width: 990px)": {
    display: "none",
  },
});
const lessonHeaderActions = css({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  width: "fit-content",
  marginLeft: "auto",
  "@media(max-width: 740px)": {
    width: "100%",
  }
},
  select(`& > div:first-of-type`, {
    paddingRight: spacing.l,

    "@media(max-width: 740px)": {
      paddingRight: spacing["s+"],
    }
  })
);

export default function Lesson({
  lesson,
  markAsWatchedHandler,
  currentClassroomId,
  prevLesson,
  lassonWatchedState,
  nextLesson,
  mockId,
  numberOfRatings,
  userId,
  user,
  classroom,
  hasClassroom,
  canExportMock,
  canComment,
  canMarkAsWatched,
  isWatched,
  isPreExam,
  dispatch,
  expiredUser,
  isFrialUser,
  showInsufficientMedcoinsModal,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [mockRevisionCard, setMockRevisionCard] = useState(false);
  const [classroomReviewType, setClassrommReviewType] = useState("");
  const [userModuleFinished, setUserModuleFinished] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const liberarRevisaoPersonalida = true;
  const toogleLeftSidebar = useCallback(
    () => setShowLeftSidebar(!showLeftSidebar),
    [showLeftSidebar]
  );
  const hideLeftSidebar = useCallback(() => setShowLeftSidebar(false));
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const hideRightSidebar = useCallback(() => setShowRightSidebar(false));
  const toogleRightSidebar = useCallback(
    () => setShowRightSidebar(!showRightSidebar),
    [showRightSidebar]
  );
  const screenWidth = useScreenWidth();
  const isMobile = makeIsMobile();

  useEffect(() => {
    if (screenWidth > 992) {
      setShowRightSidebar(false);
      setShowLeftSidebar(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (isFrialUser) return;

    if (!expiredUser) {
      const findReviewTypeBody = {
        classroom_id: getUrlParams().classroomId,
      };
      fetch(`${process.env.METRICOAPI_URL}revisions/findReviewType `, {
        method: "POST",
        body: JSON.stringify(findReviewTypeBody),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setClassrommReviewType(json.review_type);
        });
    }
  }, [userModuleFinished, lesson, numberOfRatings]);

  useEffect(() => {
    // ============= Verifica se usuário terminou o modulo todo ========================
    if (!expiredUser) {
      if (
        userId &&
        (classroomReviewType === "B2BORBASES" ||
          classroomReviewType === "REVIEW") &&
        liberarRevisaoPersonalida
      ) {
        const values = {
          userId: userId,
          lessonId: getUrlParams().lessonId,
        };
        fetch(`${process.env.METRICOAPI_URL}lesson `, {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((json) => {
            setUserModuleFinished(json.isLastLessonCompleted);

            const values = {
              userId,
              lesson_id: getUrlParams().lessonId,
              review_type: classroomReviewType,
            };
            if (json.isLastLessonCompleted) {
              fetch(
                `${process.env.METRICOAPI_URL}revisions/userQuestionsDetails`,
                {
                  method: "POST",
                  body: JSON.stringify(values),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((response) => response.json())
                .then((json) => {
                  setMockRevisionCard(true);
                  setModalData(json);
                  if (json.user.hasRevision) {
                    setButtonDisabled(true);
                    setModalOpen(false);
                  } else {
                    setButtonDisabled(false);
                    setModalOpen(true);
                  }
                })
                .catch((err) => {
                  setMockRevisionCard(false);
                });
            }
          });
      } else {
        if (userModuleFinished === true && liberarRevisaoPersonalida) {
          setUserModuleFinished(false);
        }
      }
    }
    // ============= Verifica se usuário terminou o modulo todo ========================
  }, [classroomReviewType, lesson, lassonWatchedState]);

  useEffect(() => {
    if (!classroom) return;
    if (expiredUser) return;

    const shouldFetchGenerateCertificateRequest =
      classroom.meta.progress >= 1 && classroom.meta.hasCertificate;

    if (shouldFetchGenerateCertificateRequest) {
      fetch(
        `/certificate?user_id=${userId}&classroom_id=${currentClassroomId}`,
        {
          method: "POST",
          body: JSON.stringify({
            username: user.name,
            moduleName: classroom.name,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  }, [classroom]);

  const leftSidebarContent = () => {
    let returnLink = `/classrooms/${currentClassroomId}`;
    const isMedtrackOrigin =
      getSearchParams().get("isMedtrackOrigin") === "true";

    if (isMedtrackOrigin) {
      returnLink = "/medtrack";
    }

    return (
      <>
        <div className={titleAndButton}>
          <Link to={returnLink}>
            <FaChevronLeft size={12} /> VOLTAR PARA A NAVEGAÇÃO
          </Link>
          {showLeftSidebar && (
            <Button
              onClick={toogleLeftSidebar}
              icon="close"
              size="small"
            ></Button>
          )}
        </div>
        {!expiredUser && (
          <ClassroomContentsContainer
            hideLeftSidebarHandler={hideLeftSidebar}
            compact
          />
        )}
      </>
    );
  };

  const rightSidebarContent = () => (
    <>
      {showRightSidebar && (
        <Button
          onClick={toogleRightSidebar}
          icon="close"
          size="small"
          style={{ position: "absolute", top: spacing.m, right: spacing.m }}
        ></Button>
      )}
      <AttachmentListContainer
        title="Arquivos"
        lessonId={lesson.id}
        lesson={lesson}
      />
    </>
  );

  if (expiredUser) return null;
  if (showInsufficientMedcoinsModal) {
    dispatch({
      type: "SHOW_INSUFFICIENT_MEDCOINS_MODAL",
    });
    return null;
  }

  return (lesson && screenWidth !== 0) ? (
    <Sidebar.Pushable>
      {
        isMobile &&
        <>
          <Sidebar
            animation="push"
            icon="labeled"
            visible={showLeftSidebar}
            style={leftSidebarStyle}
            width="wide"
            onHide={hideLeftSidebar}
          >
            {leftSidebarContent()}
          </Sidebar>
          <Sidebar
            animation="push"
            icon="labeled"
            visible={showRightSidebar}
            style={rightSidebarStyle}
            direction="right"
            width="wide"
            onHide={hideRightSidebar}
          >
            {rightSidebarContent()}
          </Sidebar>
        </>
      }
      <SidebarPusher>
        <div className={contentWrapper}>
          {!isMobile &&
            < div className={leftContent}>
              {hasClassroom && leftSidebarContent()}
            </div>
          }
          <div className={rightContent}>
            <div className={mainContent}>
              <div className={mobileActions}>
                <Button
                  onClick={toogleLeftSidebar}
                  icon="list ol"
                  size="tiny"
                />
                <Button
                  onClick={toogleRightSidebar}
                  icon="edit outline"
                  size="tiny"
                />
              </div>
              {userModuleFinished && mockRevisionCard && !buttonDisabled && (
                <MockRevisionReport
                  title={modalData?.module?.name.split(" - ")[0]}
                  setModalOpen={setModalOpen}
                  impact={modalData?.module?.relevance}
                  buttonDisabled={buttonDisabled}
                />
              )}

              {modalOpen && (
                <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                  <ModalReview
                    onClose={() => setModalOpen(false)}
                    data={modalData}
                    setButtonDisabled={setButtonDisabled}
                    dispatch={dispatch}
                    reviewType={classroomReviewType}
                  />
                </Dialog.Root>
              )}
              <div className={titleWrapper}>
                <h2 className={titleClass}>{lesson.name}</h2>
                <div className={lessonHeaderActions}>
                  {mockId && <TextHighlighter />}
                  {canExportMock && !isPreExam && (
                    <div className={exportMockClass}>
                      <Link
                        component={Button}
                        icon="file pdf outline"
                        content={"Exportar para PDF"}
                        href={`/mocks/${mockId}/export`}
                        target="_blank"
                        primary
                      />
                    </div>
                  )}
                </div>

              </div>
              <LessonContentContainer lesson={lesson} />
              {canMarkAsWatched && (
                <div style={buttonsWrapper}>
                  <LessonRatingFormContainer
                    form={`ratingLesson${lesson?.id}FormContainer`}
                  />
                  <Button
                    onClick={markAsWatchedHandler}
                    className={watchedButtonClass.toString()}
                    disabled={isWatched}
                  >
                    <FaRegCheckCircle size={12} />
                    {isWatched ? " Concluída" : " Marcar como Concluída"}
                  </Button>
                </div>
              )}
              <div className={butonsWrappper}>
                <Link
                  to={`/classrooms/${currentClassroomId}/lessons/${prevLesson?.id}`}
                  component={Button}
                  disabled={!prevLesson}
                >
                  <Icon name="angle left" />
                  Aula Anterior
                </Link>
                <Link
                  to={`/classrooms/${currentClassroomId}/lessons/${nextLesson?.id}`}
                  component={Button}
                  disabled={!nextLesson}
                >
                  Próxima Aula
                  <Icon name="angle right" />
                </Link>
              </div>
              {lesson.description && (
                <div
                  style={descriptionStyle}
                  dangerouslySetInnerHTML={{
                    __html: lesson.description,
                  }}
                ></div>
              )}
              {canComment && (
                <div className={commentsWrapper}>
                  <CommentsContainer lessonId={lesson.id} />
                </div>
              )}
            </div>
            {
              !isMobile &&
              <div className={computerAnnotationWrapper}>
                {rightSidebarContent()}
              </div>
            }
          </div>
        </div>
      </SidebarPusher>
    </Sidebar.Pushable >
  ) : (
    <Spinner text="Carregando informações da aula..." />
  );
}
