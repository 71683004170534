import { apiPost } from "./helpers/api";

export async function discountMedcoin(dispatch, userId, data) {
  try {
    const response = await apiPost(
      "/api/v1/user/decrement_only_user_medcoin"
    ).send(data);
    const newMedcoinAmount = parseInt(response.body.message.split(":")[1]);

    dispatch({
      type: "DISCOUNT_MEDCOIN",
      medcoinData: {
        user_id: userId,
        medcoin: newMedcoinAmount
      }
    });

    return newMedcoinAmount;
  } catch (error) {
    const errorMessage = error?.response?.body?.error;
    if (errorMessage.includes("não possui medcoins suficientes")) {
      dispatch({ type: "SHOW_INSUFFICIENT_MEDCOINS_MODAL" });
    } else {
      console.log(error);
    }
    throw new Error(error);
  }
}

export async function discountMedcoinWithLevel(dispatch, userId, data) {
  try {
    const response = await apiPost(
      "/api/v1/user_level/medcoin_decrement_with_level"
    ).send(data);
    const newMedcoinAmount = parseInt(response.body.message.split(":")[1]);

    dispatch({
      type: "DISCOUNT_MEDCOIN",
      medcoinData: {
        user_id: userId,
        medcoin: newMedcoinAmount
      }
    });

    return newMedcoinAmount;
  } catch (error) {
    const errorMessage = error?.response?.body?.error;
    if (errorMessage.includes("não possui medcoins suficientes")) {
      dispatch({ type: "SHOW_INSUFFICIENT_MEDCOINS_MODAL" });
    } else {
      console.log(error);
    }
    throw new Error(error);
  }
}

export function onlyFrontDicountMedcoin(dispatch, user) {
  dispatch({
    type: "DISCOUNT_MEDCOIN",
    medcoinData: {
      medcoin: user.medcoin - 5,
      user_id: user.id
    }
  });
}