import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const Login = styled.span`
  margin: 0 auto;
  color: ${colors.interface.grey400};
  font-size: 14px;

  a {
    color: ${colors.interface.grey400};
    font-weight: 500;
    margin-left: 8px;
    text-decoration: underline;
  }
`;

export const Goals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  @media (max-width: 1919px) {
    margin-top: 12px;
  }
`;

export const ButtonWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 0;
  margin-top: auto;
  padding: 103px 0 0 0;

  @media (max-width: 1919px) {
    padding-top: 0;
  }
`;

export const Terms = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${colors.interface.grey400};

  a {
    color: ${colors.interface.grey400};
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Text = styled.span`
  font-size: 16px;
  line-height: 24px;

  & > strong {
    font-weight: 500;
  }
`;

export const SmallText = styled.small`
  font-size: 14px;
  line-height: 21px;
`;
export const EnareObs = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.interface.grey300};
`;

export const PhoneContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  align-items: center;

  & > div > div > div {
    border-radius: 4px 0 0 4px;
  }

  & > div:nth-child(2) > div {
    border-left: hidden;
    border-radius: 0 4px 4px 0;

    &:focus-within {
      border-left: 1px solid ${colors.brand.pure};
    }
  }
`;
