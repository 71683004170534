import React from "react";
import logo from "../../../../../assets/images/logo.svg";
import logoRevalida from "../../../../../assets/images/logorevalida.svg";
import {
  BannerWrapper,
  ChildrenWrapper,
  Container,
  Content,
  Logo,
  Tag,
  Text,
} from "./styles";
import { getThemeByUniversity } from "../../../utils/getThemeByUniversity";

function Template(props) {
  const { IES, universityTheme } = getThemeByUniversity();
  const isEMROrRevalida =
    IES === "revalida" || IES === null || IES === "default";
  const banner = universityTheme.get(IES ? IES : "default").signInBanner;

  return (
    <Container>
      <BannerWrapper style={{ backgroundImage: `url(${banner})` }}>
        {isEMROrRevalida && (
          <>
            <Logo src={IES === "revalida" ? logoRevalida : logo} />
            <Tag>Conheça nossa plataforma</Tag>
            <Text>
              {IES === "revalida"
                ? "A melhor plataforma de estudos para se preparar para a prova do INEP."
                : "Experimente o poder da I.A. EMR nos seus estudos para a Residência Médica."}
            </Text>
          </>
        )}
      </BannerWrapper>

      <ChildrenWrapper hasOverflow={props.hasOverflow}>
        <Content isB2B={props.isB2B}>{props.children}</Content>
      </ChildrenWrapper>
    </Container>
  );
}

export default Template;
