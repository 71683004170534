import React from "react";
import { colors } from "../../../components/library/theme";
import { CardContainer, IconWrapper } from "./styles";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getCurrentUser } from "../../../selectors/userSelectors";
import MedcoinIndicator from "../../medcoins/medcoinIndicator";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";

function Card({ Icon, href, children, dispatch, user, isFrialUser }) {
  const history = useHistory();

  function handleClick() {
    trackClickEvents({
      eventName: "Início - interação de navegação",
      content: { NomeDoBotao: children.props.children, UserFrial: isFrialUser }
    });
    if (user.medcoin < 5 && isFrialUser) {
      dispatch({ type: "SHOW_INSUFFICIENT_MEDCOINS_MODAL" });
      return;
    }
    history.push(href);
  }

  return (
    <CardContainer target="_self" onClick={handleClick}>
      <IconWrapper>
        <Icon size={40} color={colors.brand.pure} />
        <MedcoinIndicator variant="medium">5 Medcoins</MedcoinIndicator>
      </IconWrapper>
      {children}
    </CardContainer>
  );
}

function mapStateToProps(state, ownProps) {
  const user = getCurrentUser(state);
  const isFrialUser = state?.isFrialUser;

  return {
    isFrialUser,
    user,
    ...ownProps
  };
}

export default connect(mapStateToProps)(Card);
