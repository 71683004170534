import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';
import Spinner from '../../../../components/library/Spinner';
import { colors } from '../../../../components/library/theme';
import { EMRButton } from '../../../../componentsV2/Button';

export const StyledTimeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.interface.grey150};
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: none;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  padding: 0;

  &:hover {
    background-color: ${colors.interface.grey200};
  }
`;

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 16px 24px 24px;
  width: min(600px, calc(100vw - 48px));
  margin: auto;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @media (max-width: 463px) {
    margin: 24px auto;
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    margin-bottom: 0px;
    font-weight: 600;
  }

  h2,
  p {
    color: ${colors.interface.grey400};
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 991px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 463px) {
    gap: 16px;
  }
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  flex-shrink: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100% !important;
  border-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  transition: background 0.2s ease-in-out;
  z-index: 1;
  margin-right: -8px;
  padding: 0;

  svg {
    line-height: 0;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${colors.interface.grey100};

    svg {
      color: ${colors.interface.grey500} !important;
    }
  }

  @media (max-width: 463px) {
    margin-top: -8px;
  }
`;

export const Hr = styled.hr`
  width: 100%;
  border: solid 1px ${colors.interface.grey100};
  border-bottom: 0;
  margin: 24px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  button {
    width: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

export const DialogClose = styled(Dialog.Close)`
  cursor: pointer;

  svg {
    color: ${colors.interface.grey400};
  }
`;

export const StyledSpinner = styled(Spinner)`
  border-color: rgba(0, 0, 0, 0.14) transparent transparent;
`;

export const StyledEMRButton = styled(EMRButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 991px) {
    font-size: 14px;
    height: 40px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 8px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.interface.grey400};
  }

  & > span {
    font-size: 12px;
    line-height: 18px;
    color: ${colors.interface.grey300};
  }
`;
