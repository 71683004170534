import { setCookie } from 'cookies-next';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { trackClickEvents } from '../../../../services/mixpanel/events/trackClickEvents';
import { trackLoginEvent } from '../../../../services/mixpanel/events/trackLoginEvent';
import { apiGet, apiPost } from '../../../processes/helpers/api';
import { setSession } from '../../../processes/sessionProcesses';
import { getThemeByUniversity } from '../../../utils/getThemeByUniversity';
import { EMRButton } from '../../Button';
import Input from '../../Input';
import { ACTIVE_SCREEN } from '../../SignInSecurity/enum';
import { generateFingerprint } from '../../SignInSecurity/utils/generateFingerprint';
import { requestHasEmergencyAccess } from '../../SignInSecurity/utils/requestHasEmergencyAccess';
import { requestLastSignedInDevice } from '../../SignInSecurity/utils/requestLastSignedInDevice';
import { toast } from '../../Toast';
import Template from '../template';
import { getValidator } from '../validations';
import { DownloadApp } from './DownloadApp';
import HasSession from './HasSession';
import Divisor from './divisor';
import LoginWith from './loginWith';
import {
  ButtonWrapper,
  LogoMobile,
  NewAccount,
  SignInForm,
  Title,
} from './styles';
import {
  requestUserProxySignin,
  setUserProxyCookie,
} from '../../SignInSecurity/utils/requestUserProxySignin';

export function saveTheme() {
  setCookie(process.env.METRICO_THEME_COOKIE, IES, {
    domain: process.env.DOMAIN_URL,
  });
}

function SignIn() {
  const { IES, universityTheme } = getThemeByUniversity();
  const fingerprint = generateFingerprint();
  const history = useHistory();
  const logo = universityTheme.get(IES ? IES : 'default').signInLogo;
  const isEMROrRevalida =
    IES === 'revalida' || IES === null || IES === 'default';
  const isUniversity = IES !== 'default' && IES !== null && IES !== 'revalida';
  const [isLoadingSignIn, setIsLoadingSignIn] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showHasSessionModal, setShowHasSessionModal] = useState(false);
  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });

  function validateField(field, ...params) {
    const validator = getValidator(field);
    return validator(...params);
  }

  function validateSignInForm(formData) {
    const signInData = {
      email: formData.get('email'),
      password: formData.get('password'),
    };

    const errors = {
      email: validateField('email', signInData.email),
      password: validateField('password', signInData.password),
    };

    setFormErrors(errors);

    const hasValidationError = Object.values(errors).some((error) =>
      Boolean(error)
    );

    if (hasValidationError) return false;
    return signInData;
  }

  function handleTrackPasswordRecovery() {
    trackClickEvents({ eventName: 'Clique em Esqueci minha senha' });
    saveTheme();
  }

  function handleCloseDownloadApp() {
    setShowDownloadModal((prev) => !prev);
  }

  function handleCloseHasSession() {
    setShowHasSessionModal((prev) => !prev);
  }

  function handleSignIn(signInData) {
    apiPost('/api/v1/auth/sign_in', true, true)
      .send(signInData)
      .then((res) => {
        saveTheme();
        setSession(res);
        window.location = '/';
      });
  }

  async function validateSignInSecurity(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const signInData = {
      email: formData.get('email'),
      password: formData.get('password'),
    };

    function handleSecurityPush(activeScreen, props = {}) {
      return history.push('/signin-security', {
        email: signInData.email,
        password: signInData.password,
        activeScreen,
        fingerprint,
        ...props,
      });
    }

    const validatedSignInData = validateSignInForm(formData);

    if (validatedSignInData) {
      setIsLoadingSignIn(true);

      const credentialsResponse = await apiPost(
        '/api/v1/auth/verify_credentials',
        true,
        true
      ).send(signInData);

      const areCredentialsValid = credentialsResponse.body.success;

      if (!areCredentialsValid) {
        setIsLoadingSignIn(false);
        return toast.error('E-mail e/ou senha inválidos');
      }

      const userEnrolment = await apiPost(
        '/api/v1/auth/validate_user_enabled_enrollment'
      ).send({
        email: formData.get('email'),
      });

      if (userEnrolment.body.has_active_enrollment === false) {
        return history.push('/access-denied');
      }

      const userProxySinginResponse = await requestUserProxySignin(
        signInData.email,
        signInData.password
      );
      if (!userProxySinginResponse || !userProxySinginResponse.ok) {
        setIsLoadingSignIn(false);
        return toast.error('Credenciais inválidas');
      }
      const userProxySinginResponseBody = await userProxySinginResponse.json();
      setUserProxyCookie(userProxySinginResponseBody);

      const isUserFrial = await apiGet(
        `is_frial_by_email?email=${signInData.email}`
      );

      if (isUserFrial.body.is_frial || isUniversity) {
        return handleSignIn(signInData);
      }

      const lastSignedInDevice = await requestLastSignedInDevice(
        signInData.email,
        signInData.password
      );

      if (lastSignedInDevice.fingerprint === fingerprint) {
        return handleSignIn(signInData);
      }

      if (lastSignedInDevice.fingerprint === null) {
        return handleSecurityPush(ACTIVE_SCREEN.QR_CODE_VALIDATION);
      }

      const hasEmergencyAccess = await requestHasEmergencyAccess(
        signInData.email
      );

      if (hasEmergencyAccess) {
        return handleSecurityPush(ACTIVE_SCREEN.UNAUTHORIZED_ACCESS, {
          emergencyAccess: true,
        });
      }

      handleSecurityPush(ACTIVE_SCREEN.QR_CODE_VALIDATION, {
        hasEmergencyAccess,
      });
    }
  }

  useEffect(() => {
    const iesParams = new URLSearchParams(window.location.search).get('ies');
    if (iesParams) setCookie(process.env.METRICO_THEME_COOKIE, IES);
  }, []);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => (document.body.style.overflowY = '');
  }, []);

  return (
    <Template hasOverflow={true} isB2B={!isEMROrRevalida}>
      <LogoMobile src={logo} />
      {isEMROrRevalida && (
        <>
          <NewAccount>
            Novo por aqui?
            <Link
              to="signup-v2?utm_medium=login&utm_source=plataforma"
              onClick={() =>
                trackClickEvents({
                  eventName: 'Clique em Crie sua conta gratuitamente',
                })
              }
            >
              Crie sua conta gratuitamente
            </Link>
          </NewAccount>
          <LoginWith isSignIn={true} />

          <Divisor />
        </>
      )}

      <SignInForm onSubmit={validateSignInSecurity} isB2B={!isEMROrRevalida}>
        {!isEMROrRevalida && <Title>Acessar minha conta</Title>}
        <Input
          name="email"
          error={formErrors?.email}
          label="E-mail*"
          autoFocus
        />
        <Input
          name="password"
          error={formErrors?.password}
          label="Senha*"
          ispassword={true}
        />

        <Link to="/forgot-password-v2" onClick={handleTrackPasswordRecovery}>
          Esqueci minha senha
        </Link>
        {showHasSessionModal && (
          <HasSession
            onClose={handleCloseHasSession}
            isLoading={isLoadingSignIn}
          />
        )}
        {showDownloadModal && <DownloadApp onClose={handleCloseDownloadApp} />}
        <ButtonWrapper>
          <EMRButton
            loading={isLoadingSignIn}
            fullSized
            type="submit"
            onClick={() => {
              trackLoginEvent({
                email: formData.get('email'),
              });
              trackClickEvents({
                eventName: 'Clique em Entrar (Tela de login)',
              });
            }}
          >
            Entrar
          </EMRButton>
        </ButtonWrapper>
      </SignInForm>
    </Template>
  );
}

export default SignIn;
