import styled, { css } from 'styled-components';
import { colors } from '../../../../components/library/theme';
import { infoboxVariants } from '.';
import { Link } from "react-router-dom";

export const Container = styled.div(
  ({ variant }) => css`
    width: 100%;
    padding: 16px;
    display: flex;
    gap: 8px;
    background-color: ${infoboxVariants[variant].bgColor};
    border: 1px solid ${infoboxVariants[variant].borderColor};
    border-radius: 4px;

    svg {
      flex-shrink: 0;
    }
  `
);

export const InfoWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-size: 14px;
      line-height: 21px;
      color: ${colors.interface.grey400};

      @media screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  `
);

export const LinkWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const InfoLink = styled(Link)`
  color: ${({ variant }) => infoboxVariants[variant].iconAndLinkColor};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-decoration-line: underline;
`;
