const initialState = false;

export default function onlyEnrolledStudentsModalReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "SHOW_ONLY_ENROLLED_STUDENTS_MODAL":
      return true;
    case "HIDE_ONLY_ENROLLED_STUDENTS_MODAL":
      return false;
    default:
      return state;
  }
}
