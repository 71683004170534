import BannersContainer from "../banner/BannersContainer";
import ClassroomListItem from "./ClassroomListItem";
import EmptyClassrooms from "./EmptyClassrooms";
import GoalsModal from "./goalsModal";
import Page from "../library/Page";
import React from "react";
import Spinner from "../library/Spinner";
import { css } from "glamor";
import { spacing } from "../library/theme";
import PlaceholderContentBlocked from "./PlaceholderContentBlocked";

const bannerClass = css({ marginBottom: spacing.m });
const loadingClass = css({
  display: "flex",
  justifyContent: "center",
  marginTop: spacing.m,
});

export default function Classrooms({ classrooms, loading, expiredUser, isB2BUserExamInProgress, user }) {

  return (
    <Page title="Meus Cursos">
      {
        !expiredUser &&
        <>
          {isB2BUserExamInProgress ? (<PlaceholderContentBlocked user={user} />) : (
            <>
              <GoalsModal />
              <BannersContainer bannerType="home" className={bannerClass} />
              {loading ? (
                <div className={loadingClass}>
                  <Spinner inline text="Carregando cursos..." />
                </div>
              ) : classrooms.length ? (
                classrooms.map((classroom, index) => (
                  <ClassroomListItem key={index} classroom={classroom} />
                ))
              ) : (
                <EmptyClassrooms />
              )}
            </>)
          }
        </>
      }
    </Page>
  );
}
