import styled from "styled-components";
import { colors } from "../../../components/library/theme";

function getColor(value) {
  switch (true) {
    case value < 21:
      return { bg: "#FFE9E9", text: colors.status.red300 };
    case value < 61:
      return { bg: "#FCFBD4", text: colors.status.yellow200 };
    case value > 61:
      return { bg: "#EFFFF7", text: colors.brand.pure };
    default:
      return { bg: "#FFE9E9", text: colors.status.red300 };
  }
}

export const Container = styled.div`
  padding: 16px;
  border: 1px solid ${colors.interface.grey200};
  background: #f7fafc;
  margin: 0 20px 20px 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;

  :hover {
    background: ${(props) => getColor(props.value).bg};
    border: 1px solid ${(props) => getColor(props.value).text};
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  line-height: 21px;
  height: 21px;
  color: ${(props) => getColor(props.value).text};
`;

export const Bar = styled.div`
  height: 6px;
  border-radius: 6px;
  background-color: ${(props) => getColor(props.value).text};
  width: ${(props) => props.value}%;
`;

export const Progress = styled.div`
  height: 6px;
  border-radius: 6px;
  width: 100%;
  background-color: #d9d9d9;
  overflow: hidden;
`;
