import React, { useContext } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import { context, Provider } from "./context";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import { StepsIndicator } from "./stepsIndicator";
import { Modal, Overlay } from "./styles";

function Main(props) {
  return (
    <Provider user={props.user} {...props}>
      <SuggestionsModal />
    </Provider>
  );
}

function SuggestionsModal() {
  const { step, user } = useContext(context);
  const steps = [<Step1 />, <Step2 />, <Step3 />];

  return (
    <Overlay>
      {user && (
        <Modal>
          <StepsIndicator />
          {steps[step]}
        </Modal>
      )}
    </Overlay>
  );
}

function mapStateToProps(state) {
  return {
    user: getCurrentUser(state),
  };
}
export default connect(mapStateToProps)(Main);
