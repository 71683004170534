import styled from 'styled-components';
import { colors } from '../../../components/library/theme';
import { EMRButton } from '../../Button';
import * as Dialog from '@radix-ui/react-dialog';

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow: auto;
  inset: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 16px;
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  width: 100%;
  max-width: 516px;
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  max-height: calc(100vh - 32px);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
  hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @media (max-width: 991px) {
    justify-content: unset;
    height: unset;
    overflow: auto;
  }

  p {
    line-height: 24px;
  }


  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;

  h2 {
    color: ${colors.interface.grey400};
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
  }

  button {
    cursor: pointer;
    padding: 0;
    border: none;
    margin-left: auto;
    background: none;
    height: 100%;
  }
`;
export const Button = styled(EMRButton)`
  font-size: 14px;
  font-weight: 600;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;
export const CardsWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
export const Wrapper = styled.div`
  width: 100%;
`;
