import React from "react";
import { IoClose } from "react-icons/io5";
import {
  CardsWrapper,
  DialogOverlay,
  DialogContent,
  Text,
  TitleWrapper,
  Wrapper
} from "./styles";
import { colors } from "../../../components/library/theme";
import { Card } from "./card";
import * as Dialog from '@radix-ui/react-dialog';


export default function ExplanationMedcoinModal({ handleModal, isOpen }) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={handleModal}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <TitleWrapper>
              <h2>Sobre as Medcoins</h2>
              <Dialog.Close>
                <IoClose size={32} fill={colors.interface.grey400} />
              </Dialog.Close>
            </TitleWrapper>

            <Wrapper>
              <Text style={{ marginBottom: 8 }}>Valores:</Text>
              <CardsWrapper>
                <Card title="Aula" value={10} />
                <Card title="Simulado" value={5} />
              </CardsWrapper>
            </Wrapper>

            <Text>
              As medcoins são as suas moedas de troca para acessar nossos recursos premium: Aulas e Simulados.
              <br /><br />
              Cada Aula (com seus materiais de apoio) custa 10 Medcoins e cada Simulado custa 5 Medcoins.
              <br /><br />
              A partir de agora você tem 100 Medcoins para usar como preferir e degustar a incrível experiência de ser aluno EMR
            </Text>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
