import React from "react";
import reactDom from "react-dom";
import { TooltipContent } from "./tooltipContent";

let tooltipEl = null;

export function Tooltip(e, children) {
  if (tooltipEl) return;

  const parent = e.currentTarget;
  const parentDimensios = parent.getBoundingClientRect();
  tooltipEl = document.createElement("div");

  function removeTooltip() {
    if (tooltipEl) {
      document.body.removeChild(tooltipEl);
      return tooltipEl = null;
    }
  }

  parent.addEventListener("mouseleave", function () {
    removeTooltip()
  });

  const observer = new MutationObserver(function (mutationsList) {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList' && !document.body.contains(parent)) {
        removeTooltip()
        observer.disconnect();
      }
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });

  reactDom.render(
    <TooltipContent>{children}</TooltipContent>,
    document.body.appendChild(tooltipEl)
  );

  tooltipEl.style.position = "absolute";
  const tooltipDimensios = tooltipEl.getBoundingClientRect();

  tooltipEl.style.left =
    parentDimensios.x -
    tooltipDimensios.width / 2 +
    parentDimensios.width / 2 +
    "px";

  const top = parentDimensios.top + window.pageYOffset;

  tooltipEl.style.top = top - tooltipDimensios.height - 12 + "px";
  tooltipEl.style.zIndex = 99;
}
