import * as Dialog from "@radix-ui/react-dialog";
import React, { useEffect, useState } from "react";
import { medtrackApi } from "../../../../../services/medtrack";
import { colors } from "../../../../components/library/theme";
import { BigAreaTag } from "./BigAreaTag";
import { LessonCheckbox } from "./LessonCheckbox";
import { LevelGroupName } from "./LevelGroupName";
import { IoClose } from "react-icons/io5";
import {
  CloseButton,
  DesktopLevelGroupName,
  DialogContent,
  DialogOverlay,
  Header,
  MobileLevelGroupName,
  MocksItemSkeletonContainer,
  StyledInfobox,
  StyledTypeTag,
} from "./styles";
import { LevelSelect } from "./LevelSelect";
import { BasesLessonCheckbox } from "./BasesLessonCheckbox";
import { useMedTrack } from "../../hooks/useMedTrack";
import { MockTodoItem } from "../MockTodoItem";
import { SkeletonStyle } from "../Skeleton/styles";

export function TodoItemModal({
  isModalOpen,
  onCloseModal,
  todo,
  handleTaskStatus,
  isBases,
  isRevision,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [toDoItemDetails, setToDoItemDetail] = useState();
  const [isAnyMockBeingCreated, setIsAnyMockBeingCreated] = useState(false);
  const shouldRenderLevelSelect = isBases && todo.type.category === "theory";
  const isBasesWithoutLevel = isBases && toDoItemDetails?.title?.length <= 0;
  const fixationMockQuestionsQty = isBases ? "10" : "15";
  const { getTodoGroups } = useMedTrack();

  async function getTodoItemDetail() {
    try {
      const response = await medtrackApi.get(`/todo-item/${todo.id}`);
      const data = response.body;

      if (data) setToDoItemDetail(data);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function handleSelectLevel(levelId) {
    setIsLoading(true);
    try {
      await medtrackApi.put(`/todo-item/${todo.id}/level`, {
        levelId: levelId,
      });
      onCloseModal();
      setIsLoading(false);
      getTodoGroups();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  function getPracticeMessage() {
    if (toDoItemDetails?.category === "practice" && isRevision) {
      return `Esses são os conteúdos indicados para você estudar esta semana. Você também pode criar um outro tipo de simulado.`;
    } else if (
      toDoItemDetails?.category === "practice" &&
      toDoItemDetails?.subCategory === "questions"
    ) {
      return `Sugerimos que você faça ${toDoItemDetails?.questionSuggestionNumber} questões para atingir sua meta semanal. Escolha entre Simulado Personalizados, Simulados Inteligentes ou Provas na íntegra. Ao finalizar, volte ao Medtrack e marque a tarefa concluída.`;
    } else {
      const simululadosfixacaoText = isBases
        ? `Crie um Simulado Personalizado de 10 questões do assunto ${todo.title} para fixar seu conhecimento nesse assunto. Você pode personalizar as demais informações do simulado conforme desejar (bancas, anos, tipo de prova). Ao finalizar, volte ao Medtrack e marque a tarefa concluída.`
        : `Acesse o simulado de fixação indicado que contém ${fixationMockQuestionsQty} questões sobre o assunto ${toDoItemDetails?.title} para atingir sua meta semanal. Ao finalizar volte ao Medtrack e marque a tarefa concluída.`;
      return simululadosfixacaoText;
    }
  }

  function getPracticeLink() {
    if (isRevision) return null;

    if (
      toDoItemDetails?.category === "practice" &&
      toDoItemDetails?.subCategory === "questions"
    ) {
      return "Criar simulado";
    }

    return "Fazer simulado";
  }
  useEffect(() => {
    if (isModalOpen) getTodoItemDetail();
  }, [isModalOpen]);

  const InfoBoxContent = {
    basesNoLevel: {
      message:
        "Defina aqui o assunto que deseja estudar. Você pode escolher algum assunto que esteja vendo no seu internato ou um assunto sugerido pelo Medtrack (marcados com uma estrela, possuem alta relevância de acordo com seus objetivos personalizados). Em seguida, clique no botão da tarefa selecionada para ser direcionado para ela.",
      linkText: "",
    },
    theory: {
      message:
        "Acesse o conteúdo indicado para você estudar esta semana. Ao finalizar volte ao Medtrack e marque a tarefa concluída.",
      linkText: "Acessar conteúdo",
    },
    revision_lesson: {
      message:
        "Acesse o conteúdo indicado para você estudar esta semana. Ao finalizar volte ao Medtrack e marque a tarefa concluída.",
      linkText: "Acessar conteúdo",
    },
    revision: {
      message:
        "Acesse a área ‘minha agenda’ e faça revisões da semana atual. Ao finalizar volte ao Medtrack e marque a tarefa concluída.",
      linkText: "Acessar revisões",
    },
    practice: {
      message: getPracticeMessage(),
      linkText: !isRevision && getPracticeLink(),
    },
  };

  if (!toDoItemDetails) return <></>;
  const url = new URL(toDoItemDetails?.link);
  url.searchParams.set("isMedtrackOrigin", "true");
  let todoItemLink = url.pathname + url.search;
  const isFromOtherHost = window.location.hostname !== url.hostname;
  if (isFromOtherHost) todoItemLink = toDoItemDetails?.link;

  function renderContent() {
    if (isRevision && toDoItemDetails?.category === "practice") {
      return todo.mocks.map(({ mock, mockTemplate, questionsCount }, i) => (
        <MockTodoItem
          key={`${mock?.id}-${mockTemplate?.id}`}
          mock={mock}
          mockTemplate={mockTemplate}
          quantityQuestions={questionsCount}
          isFirstElement={i == 0}
          isLastElement={i + 1 == todo.mocks.length}
          isCompleted={Boolean(mock?.finishedAt)}
          isAnyMockBeingCreated={isAnyMockBeingCreated}
          setIsAnyMockBeingCreated={setIsAnyMockBeingCreated}
        />
      ));
    } else if (isBases) {
      return (
        <BasesLessonCheckbox
          {...toDoItemDetails}
          todo={todo}
          handleTaskStatus={handleTaskStatus}
        />
      );
    } else {
      return (
        <LessonCheckbox
          {...toDoItemDetails}
          todo={todo}
          handleTaskStatus={handleTaskStatus}
        />
      );
    }
  }

  const content = renderContent();

  return (
    <Dialog.Root open={isModalOpen}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <Header>
              <div>
                <StyledTypeTag variant={toDoItemDetails?.category} />
                {toDoItemDetails?.bigArea && (
                  <BigAreaTag variant={toDoItemDetails?.bigArea} />
                )}

                {toDoItemDetails?.levelGroupName && !isRevision && (
                  <DesktopLevelGroupName>
                    <LevelGroupName
                      levelGroupName={toDoItemDetails.levelGroupName}
                    />
                  </DesktopLevelGroupName>
                )}
              </div>

              <CloseButton onClick={onCloseModal}>
                <IoClose size={24} color={colors.interface.grey300} />
              </CloseButton>
            </Header>

            {isLoading ? (
              <MocksItemSkeletonContainer>
                {Array(3)
                  .fill(0)
                  .map((_, i) => (
                    <SkeletonStyle
                      key={i}
                      width="100%"
                      height="64px"
                      borderRadius="4px"
                    />
                  ))}
              </MocksItemSkeletonContainer>
            ) : (
              content
            )}

            {toDoItemDetails?.levelGroupName && !isRevision && (
              <MobileLevelGroupName>
                <LevelGroupName
                  levelGroupName={toDoItemDetails.levelGroupName}
                />
              </MobileLevelGroupName>
            )}

            <StyledInfobox
              variant={"lightGreen"}
              shouldOpenInAnotherTab={isFromOtherHost}
              linkTo={todoItemLink}
              linkText={
                isBasesWithoutLevel
                  ? ""
                  : InfoBoxContent[toDoItemDetails?.category].linkText
              }
            >
              {isBasesWithoutLevel
                ? InfoBoxContent.basesNoLevel.message
                : InfoBoxContent[toDoItemDetails?.category].message}
            </StyledInfobox>

            {shouldRenderLevelSelect && (
              <LevelSelect
                isCompleted={todo.status === "completed"}
                onLevelSelect={handleSelectLevel}
                onCloseModal={onCloseModal}
                isUpdatingLevel={isLoading}
                levelName={toDoItemDetails?.title}
              />
            )}
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
