import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  button {
    font-size: 16px;
    font-weight: 600;
  }
`;
export const BannerWrapper = styled.div`
  align-items: flex-start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  justify-content: space-between;
  padding: 80px;
  position: relative;
  width: 60%;

  @media (max-width: 1000px) {
    display: none;
  }

  @media (min-width: 1920px) {
    padding: 120px;
  }
`;
export const Logo = styled.img`
  width: 266px;

  @media (max-width: 1919px) {
    width: 167px;
  }
`;
export const Tag = styled.span`
  border: solid 2px #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: auto;
  padding: 0 16px;
  text-align: center;
`;
export const Text = styled.h1`
  color: #fff;
  font-weight: 600;
  line-height: 1.5;
  font-size: 40px;

  @media (max-width: 1919px) {
    font-size: 24px;
  }
`;
export const ChildrenWrapper = styled.section`
  display: flex;
  align-items: center;
  min-width: 542px;
  width: 40%;
  overflow-y: ${(props) => (props.hasOverflow ? "hidden" : "scroll")};

  @media (max-width: 1919px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    min-width: unset;
    padding: 24px;
    width: 100%;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  margin: 0 auto;
  max-width: 460px;
  width: 100%;
  max-height: 840px;

  @media (max-width: 1919px) {
    max-width: 377px;
    max-height: 577px;
  }

  & > :last-child {
    padding-bottom: 64px;
  }

  ${({ isB2B }) => isB2B && `
    justify-content:center; 

    & > :last-child {
      padding-bottom: unset;
    }
  `}
`
