import * as Dialog from "@radix-ui/react-dialog";
import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";
import { EMRButton } from "../../../Button";

export const ConfirmButton = styled(EMRButton)(css`
  padding: 8px 24px;
  font-weight: 600;
  font-size: 14px;
  gap: 8px;
  height: 40px;
`);

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(496px, calc(100vw - 48px));
  max-height: 85vh;
  margin: auto;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  height: fit-content;
  gap: 16px;

  button {
    height: 48px;
  }

  @media (max-width: 740px) {
    flex-direction: column-reverse;
  }
`;



export const DialogTitle = styled(Dialog.Title)`
  color: ${colors.interface.grey500};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
`;

export const DialogDescription = styled(Dialog.Description)`
  margin-bottom: 32px;
  font-size: 16px;
  color: ${colors.interface.grey400};
  line-height: 1.5;

  @media (max-width: 740px) {
    font-size: 14px;
  }
`;
