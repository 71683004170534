import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { spacing } from "./theme";
import Link from "./Link";
import { FaChevronLeft } from "react-icons/fa";
import { connect } from "react-redux";
import { Tab } from "semantic-ui-react";
import { EMRButton } from "../../componentsV2/Button";
import { getCurrentUser } from "../../selectors/userSelectors";
import MedcoinIndicator from "../../componentsV2/medcoins/medcoinIndicator";
import { apiPost } from "../../processes/helpers/api";
import { onlyFrontDicountMedcoin } from "../../processes/medcoinProcess";
import { makeIsMobile } from "../../utils/useScreenWidth";
import { PageAction } from "./PageAction";
import {
  ButtonContainer,
  header,
  linkStyle,
  mobileAction,
  mobileHeader,
  paneStyle,
  row,
  tabMenuOptions,
} from "./PageStyles";
import { isQuestionsCommentsPage } from "./utils";
function Page(props) {
  const {
    title,
    backlink,
    backlinkClickHandler,
    action,
    children,
    panels,
    pageIsIntelligent,
    user,
    isFrialUser,
    dispatch,
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);
  const backlinkEnabled = backlink || backlinkClickHandler;
  const isMobile = makeIsMobile();
  const panelMode = Array.isArray(panels);

  const [activeTab, setActiveTab] = useState(0);
  const pageAction = panelMode ? panels[activeTab]?.pageAction : action;
  const tabChangeHandler = useCallback((_, { activeIndex }) => {
    setActiveTab(activeIndex);
  }, []);

  const headerClass = css(
    header,
    isMobile && mobileHeader,
    panelMode && paneStyle
  );

  const content = css({
    padding: isQuestionsCommentsPage() && !isMobile ? spacing["m+"] : spacing.m,
  });

  const handleSubmit = () => {
    setLoading(true);
    const session = JSON.parse(localStorage.getItem("session"));
    const userId = session.currentUserId;
    apiPost(`${process.env.METRICOAPI_URL}user/findTypeCourseOfUser`, true)
      .send(JSON.stringify({ userId }))
      .then((response) => {
        const values = {
          user_id: userId,
          evaluation_type: response.body.evaluationTypes,
        };
        apiPost("/api/v1/mocks/generate_smart_evaluation", true)
          .send(JSON.stringify(values))
          .then((response) => {
            const id = response.body.mockID;
            props.history.push(`/mocks/${id}`);

            if (user.isFrial) {
              onlyFrontDicountMedcoin(dispatch, user);
            }
          })
          .catch((error) => {
            setLoading(false);
            const errorMessage = error?.response?.body?.errors;
            if (errorMessage.includes("Não tem medcoins suficientes")) {
              dispatch({ type: "SHOW_INSUFFICIENT_MEDCOINS_MODAL" });
            } else {
              console.log(error);
            }
          });
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div {...rest}>
      {title && (
        <div className={headerClass}>
          <div className={row}>
            {backlinkEnabled && (
              <Link
                to={backlink}
                onClick={backlinkClickHandler}
                style={linkStyle}
              >
                <FaChevronLeft size={12} /> VOLTAR
              </Link>
            )}
            <h3>{title}</h3>
          </div>
          {pageIsIntelligent ? (
            <>
              {!isMobile && (
                <>
                  <PageAction isMobile={isMobile} content={pageAction} />
                  <ButtonContainer
                    style={{
                      padding: "0 8px",
                      minWidth: 286,
                    }}
                  >
                    <EMRButton
                      loading={loading}
                      onClick={() => handleSubmit()}
                      fontSize="lg"
                      mediumWeight
                      style={{
                        padding: "0 8px",
                        minWidth: 286,
                      }}
                    >
                      + Criar Simulado Inteligente
                    </EMRButton>
                    <MedcoinIndicator variant="large">
                      5 Medcoins
                    </MedcoinIndicator>
                  </ButtonContainer>
                </>
              )}
            </>
          ) : (
            <PageAction isMobile={isMobile} content={pageAction} />
          )}
        </div>
      )}
      {panelMode ? (
        <>
          {isMobile && (
            <div className={mobileAction}>
              <PageAction mobile isMobile={isMobile} content={pageAction} />
            </div>
          )}
          <Tab
            activeIndex={activeTab}
            onTabChange={tabChangeHandler}
            panes={panels}
            menu={tabMenuOptions}
          />
        </>
      ) : (
        <div className={content}>
          <PageAction mobile isMobile={isMobile} content={action} />
          {children}
        </div>
      )}
    </div>
  );
}
function mapStateToProps(state, ownProps) {
  const user = getCurrentUser(state);
  const isFrialUser = state?.isFrialUser;

  return {
    isFrialUser,
    user,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(Page);
