import React from 'react';
import { Container, MedcoinMobile } from './styles';
import MedcoinIndicator from '../../../componentsV2/medcoins/medcoinIndicator';

export default function MockStatus({ value, isFinished, hasMedcoin = false }) {
  function getColor() {
    if (isFinished) return 'completed';
    if (value === 0 || value === null) return 'pending';
    if (value > 0 && value < 1) return 'onGoing';
  }

  const getStatus = () => {
    if (isFinished) return 'Finalizado';
    if (value === 0 || value === null) return 'Pendente';
    if (value > 0 && value < 1) return 'Em andamento';
  };

  const status = getStatus();
  const statusColors = getColor();

  return (
    <>
      <Container hasMedcoin={hasMedcoin} mode={statusColors}>{status}</Container>

      {
        hasMedcoin && (
          <MedcoinMobile>
            <MedcoinIndicator>5 Medcoins</MedcoinIndicator>
          </MedcoinMobile>
        )
      }
    </>
  );
}
