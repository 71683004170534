import React, { useEffect, useState } from "react";
import { css } from "glamor";
import { Grid, Select, Table } from "semantic-ui-react";
import Spinner from "../library/Spinner";
import { spacing, stateColors, uiColors } from "../library/theme";
import MockTemplateListItemContainer from "./MockTemplateListItemContainer";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import Pagination from "../library/Pagination";
import MockProgressContainer from "./MockProgressContainer";
import { fetchPaginatedMock } from "../../processes/mockProccess";
import { MocksTable } from "../../componentsV2/MocksTable";
import { FilterButton } from "../../componentsV2/FilterButton";

const contentWrapper = {
  borderTop: `1px solid ${uiColors.border}`,
  textAlign: "center"
};
const emptyClass = css({
  textAlign: "center",
  color: stateColors.empty,
  padding: `${spacing.m} 0`
});
const loadingWrapper = {
  margin: spacing.xxl
};

export default function Mocks(props) {
  const { classrooms } = props;

  const [classroomId, setClassroomId] = useState("");
  const [mocks, setMocks] = useState([]);
  const [selectedValue, setSelectedValue] = useState("ALL");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");

  const buttonFIlterList = [
    {
      action: "ALL",
      title: "Todos"
    },
    {
      action: "IN_PROGRESS",
      title: "Em andamento"
    },
    {
      action: "DONE",
      title: "Finalizados"
    },
    {
      action: "PENDING",
      title: "Pendentes"
    }
  ];

  const handleFetch = (action, page) => {
    setIsLoading(true);

    if (action) setStatus(action);
    if (action === "ALL") {
      setStatus("");
    }

    fetchPaginatedMock(action || status, "GENERAL", page, classroomId)
      .then((mockList) => setMocks(mockList))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleFetch();
  }, [classroomId]);

  return (
    <Grid>
      <Grid.Column computer={8} mobile={16}>
        <Select
          value={classroomId}
          onChange={(_, { value }) => setClassroomId(value)}
          options={makeDropDownOptions(classrooms, "Selecione uma turma")}
          placeholder="Selecione uma turma"
          fluid
        />
      </Grid.Column>
      <Grid.Column computer={16} style={contentWrapper}>
        <FilterButton
          data={buttonFIlterList}
          isLoading={isLoading}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          onSelectFilter={handleFetch}
        />
        <MockProgressContainer mockTemplateType="highlighted" />
        {isLoading ? (
          <div style={loadingWrapper}>
            <Spinner inline />
          </div>
        ) : (
          <MocksTable variant={"mockTemplate"}>
            {!!mocks?.dataMocks?.length ? (
              <>
                <thead>
                  <tr>
                    <th>Criação</th>
                    <th>Nome</th>
                    <th>Status</th>
                    <th>Nota</th>
                    <th>Progresso</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {mocks?.dataMocks?.map((mock) => (
                    <MockTemplateListItemContainer
                      key={mock.id}
                      mockTemplate={mock}
                      hasMedcoin
                    />
                  ))}
                </tbody>
              </>
            ) : (
              <Table.Body>
                <tr>
                  <td className={emptyClass}>Nenhum resultado encontrado</td>
                </tr>
              </Table.Body>
            )}
          </MocksTable>
        )}
        <Pagination
          fetchHandler={handleFetch}
          list={mocks}
          loading={isLoading}
        />
      </Grid.Column>
    </Grid>
  );
}
