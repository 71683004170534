import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";
import { colors } from "../../../../components/library/theme";
import { TypeTag } from "../TypeTag";
import { Infobox } from "../Infobox";

export const MocksItemSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 24px 0;
`;

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(600px, calc(100vw - 48px));
  margin: auto;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  flex-shrink: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100% !important;
  border-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  transition: background 0.2s ease-in-out;
  z-index: 1;
  margin: -8px -8px 0 0;
  padding: 0;

  svg {
    line-height: 0;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${colors.interface.grey100};

    svg {
      color: ${colors.interface.grey500} !important;
    }
  }

  @media (max-width: 463px) {
    margin-top: -8px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    @media screen and (max-width: 460px) {
      flex-direction: column;
    }
  }
`;

export const StyledTypeTag = styled(TypeTag)`
  @media screen and (max-width: 991px) {
    height: 32px;

    span {
      font-size: 12px;
      line-height: 18px;
    }
  }

  @media (max-width: 640px) {
    display: flex;
    order: 0;
    width: fit-content;
  }

  @media screen and (max-width: 460px) {
    width: 100%;
  }
`;

export const MobileLevelGroupName = styled.div`
  margin-bottom: 16px;
  font-size: 14px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const DesktopLevelGroupName = styled.div`
  font-size: 14px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledInfobox = styled(Infobox)`
  @media screen and (max-width: 991px) {
    span {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
