import { css } from "glamor";
import React, { useMemo } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiLockAlt } from "react-icons/bi";
import { MdRadioButtonUnchecked } from "react-icons/md";
import MedcoinIndicator from "../../componentsV2/medcoins/medcoinIndicator";
import formatDate, { isAfterToday } from "../../utils/formatDate";
import LessonListItemContainer from "../lessons/LessonListItemContainer";
import { spacing, typographyColors, uiColors } from "../library/theme";
import LevelMockListItemContainer from "./LevelMockListItemContainer";

const titleWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: spacing.m,
  marginBottom: spacing.m,
  width: "100%",
  gap: spacing.xs,
});
const titleAndDuration = css({
  flex: 1,
});
const titleClass = css({
  color: uiColors.backgroundDark,
  textTransform: "uppercase",
  fontWeight: "bold",
  margin: 0,
  marginRight: spacing.xs,
  display: "flex",
  gap: 8,
  alignItems: "center",
});
const infoClass = css({
  color: typographyColors.defaultBody,
  fontSize: 10,
});

const checkCircle = {
  color: uiColors.background,
  marginRight: spacing.s,
};

export default function LevelListItem({
  level,
  lessons,
  compact,
  mockTemplates,
  isFrialUser,
  levelGroupPosition,
  isLevelPurchased,
}) {

  const medcoinTooltipText = isLevelPurchased
    ? "Você já possui essa aula"
    : "10 Medcoins";


  const memoizedLessons = useMemo(() =>
    lessons?.map((lesson, index) => (
      <LessonListItemContainer
        key={index}
        isFrialUser={isFrialUser}
        level={level}
        lesson={lesson}
        compact={compact}
        levelGroupPosition={levelGroupPosition}
        isLevelPurchased={isLevelPurchased}
      />
    )), [lessons, compact, levelGroupPosition, isLevelPurchased]);

  return (
    <>
      <div className={titleWrapper}>
        <div className={titleAndDuration}>
          <h5 className={titleClass}>
            {isFrialUser && levelGroupPosition > 1 && (
              <>
                {level.enabledToBuy ? (
                  <MedcoinIndicator isLevelPurchased={isLevelPurchased}>
                    {medcoinTooltipText}
                  </MedcoinIndicator>
                ) : (
                  <BiLockAlt style={{ minWidth: 20 }} size={20} />
                )}
              </>
            )}
            {level.name}
          </h5>
          {level.startsAt && isAfterToday(level.startsAt) && (
            <p className={infoClass}>
              (Este módulo será liberado em{" "}
              {formatDate(level.startsAt, "DD/MM/YYYY")})
            </p>
          )}
        </div>
        {level.meta.progress >= 1 ? (
          <AiFillCheckCircle style={checkCircle} size={22} />
        ) : (
          <MdRadioButtonUnchecked style={checkCircle} size={22} />
        )}
      </div>

      {memoizedLessons}

      {mockTemplates?.map((mockTemplate, index) => (
        <LevelMockListItemContainer
          key={index}
          mockTemplate={mockTemplate}
          compact={compact}
        />
      ))}
    </>
  );
}
