import React, { useEffect, useRef, useState } from "react";
import { MedTrackTooltip } from "../MedTrackTooltip";
import { TodoItemModal } from "../TodoItemModal";
import { TypeTag } from "../TypeTag";
import { DesktopDescription } from "./Description";
import { TodoButton } from "./TodoButton";
import {
  Bar,
  Container,
  InfosWrapper,
  TagContainer,
  TaskName,
  Wrapper,
} from "./styles";
import { CheckBox } from "../CheckBox";
import { useMedTrack } from "../../hooks/useMedTrack";
import { trackClickEvents } from "../../../../../services/mixpanel/events/trackClickEvents";

export function TodoItem({
  onStatusChange,
  todo,
  isAccordionOpen,
  handleTodoModal,
  handleUncheck,
  isGroupCompleted,
  hasTopBorder,
  isBases,
  isRevision,
  totalTodoDurationHours,
  isLoading,
}) {
  const { activeFilters } = useMedTrack();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const { description, subCategory, category } = todo.type;
  const revisionDescription = isRevision &&
    todo.type.category === "revision_lesson" && {
      ...todo.type,
      description: "30 min",
    };

  const isCompleted = todo.status === "completed" || todo.isCheckedByUser;
  const isLocked = todo.status === "locked";
  const { modalOpenId, setModalOpenId } = handleTodoModal;
  const hasNoTitle = todo.title.length <= 0;
  const barRef = useRef(null);
  const todoTitle =
    isBases && hasNoTitle ? `Defina o assunto ${todo?.order}` : todo.title;

  const todoDuration = todo.duration;
  const todoProgressPercentage = Math.round(
    (todoDuration * 100) / totalTodoDurationHours
  );

  const shouldHideTooltip =
    activeFilters.category !== "" || !isTooltipVisible || !isRevision;

  const containerRef = (element) => {
    if (element && barRef.current) {
      const infoWrapperHeight = element.clientHeight - 16;
      barRef.current.style.height = infoWrapperHeight + "px";
    }
  };

  function isAnyMockCompleted(mocks) {
    return mocks?.every(
      (item) => item.mock !== null && item.mock.finishedAt !== null
    );
  }

  function automaticCheckMockTodoItem() {
    if (isRevision && todo.mocks) {
      const anyCompleted = isAnyMockCompleted(todo.mocks);
      if (anyCompleted && !todo.isCheckedByUser) {
        onStatusChange(todo.id, true, totalTodoDurationHours);
      }
    }
  }

  useEffect(() => {
    automaticCheckMockTodoItem();
  }, [todo.mocks]);

  async function handleTaskStatus(e) {
    if (isLocked || isLoading || (isBases && hasNoTitle)) return;
    e.stopPropagation();

    try {
      if (isGroupCompleted) {
        return handleUncheck(todo.id, !todo.isCheckedByUser);
      }

      onStatusChange(todo.id, !todo.isCheckedByUser, totalTodoDurationHours);
    } catch (error) {
      console.error(error);
    }
  }

  function redirectToTask(e) {
    if (isLocked) return;
    setModalOpenId(todo.id);
    trackClickEvents({
      eventName: "Clique em tarefa no Medtrack",
      content: { Tarefa: todo.title },
    });
    e.stopPropagation();
  }

  function hideTooltip() {
    if (!isAccordionOpen) return true;
    return !isLocked;
  }

  function clearModalId() {
    setModalOpenId(null);
  }

  const isModalOpen = todo.id === modalOpenId;

  const getDescriptionProps = () => {
    if (isRevision && description && category === "practice") {
      return { category, subcategory: subCategory, text: description };
    } else if (isRevision && description && category === "revision") {
      return { category, subcategory: subCategory, text: description };
    } else {
      return {
        category: revisionDescription.category,
        subcategory: revisionDescription.subCategory,
        text: revisionDescription.description,
      };
    }
  };

  const {
    category: descCategory,
    subcategory: descSubCategory,
    text: descText,
  } = getDescriptionProps();

  useEffect(() => {
    const checkIsDesktop = () => {
      setIsDesktop(window.matchMedia("(min-width: 1024px)").matches);
    };

    checkIsDesktop();

    window.addEventListener("resize", checkIsDesktop);

    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);

  return (
    <MedTrackTooltip
      isHidden={hideTooltip()}
      variant="todoLocked"
      width={isBases ? "217px" : "233px"}
      isBases={isBases && hasNoTitle}
      text={
        isBases && hasNoTitle
          ? "Adicione acima um assunto na tarefa de teoria para liberar o acesso as questões de fixação."
          : "No momento você não consegue adiantar esse conteúdo pois não está disponível. Mas fique tranquilo, o conteúdo estará liberado em breve."
      }
    >
      <Container
        ref={containerRef}
        className="todoItem"
        isLocked={isLocked}
        hasTopBorder={hasTopBorder}
        onClick={redirectToTask}
      >
        <Bar ref={barRef} isLocked={isLocked} category={category} />

        <Wrapper>
          <div
            className="content"
            onMouseEnter={() => isDesktop && setIsTooltipVisible(true)}
            onMouseLeave={() => isDesktop && setIsTooltipVisible(false)}
          >
            <MedTrackTooltip
              isHidden={shouldHideTooltip}
              hasSmallText
              width="max-content"
              variant="todoItemProgress"
              text={`Representa ${todoProgressPercentage}% do progresso semanal`}
            >
              <InfosWrapper isRevision={isRevision}>
                <CheckBox
                  isDisabled={isLocked}
                  onCheck={handleTaskStatus}
                  isChecked={isCompleted}
                />

                <TaskName isLocked={isLocked}>{todoTitle}</TaskName>
              </InfosWrapper>

              <TypeTag isDisabled={isLocked} variant={todo.type.category} />

              <DesktopDescription
                category={descCategory}
                subcategory={descSubCategory}
                isRevision={isRevision}
              >
                {descText}
              </DesktopDescription>

              {description && !isRevision && (
                <DesktopDescription
                  category={category}
                  subcategory={subCategory}
                >
                  {description}
                </DesktopDescription>
              )}
            </MedTrackTooltip>
          </div>

          <TodoButton isLocked={isLocked} />
        </Wrapper>
      </Container>

      {isModalOpen && (
        <TodoItemModal
          todo={todo}
          isModalOpen={isModalOpen}
          handleTaskStatus={handleTaskStatus}
          onCloseModal={clearModalId}
          isBases={isBases}
          isRevision={isRevision}
        />
      )}
    </MedTrackTooltip>
  );
}
