import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 100%;
  max-height: 840px;

  & > div:first-of-type {
    margin-bottom: 16px;
  }
  
  a {
    margin-top: 8px;
    color: ${colors.interface.grey400} !important;
    font-size: 14px;
    margin-left: auto;
    
  }
  ${({isB2B}) => isB2B && `
    max-height:none;
    height: initial;
    a{
      margin-bottom: 32px;
    }
  `}
`

export const NewAccount = styled.span`
  margin: 0 auto;
  color: ${colors.interface.grey400};
  font-size: 14px;

  a {
    color: ${colors.interface.grey400};
    font-weight: 500;
    margin-left: 8px;
    text-decoration: underline;
  }
`
export const ButtonWrapper = styled.fieldset`
  border: 0;
  margin-top: auto;
  padding: 0;
`
export const LogoMobile = styled.img`
  margin: 0 auto;
  margin-bottom: 34px;
  width: 209px;
  max-height: 82px;

  @media (min-width: 1000px) {
    display: none;
  }
` 

export const Title = styled.p`
  text-align: center;
  margin-bottom: 32px;
  font-size: 18px;
  font-weight: 600;
`