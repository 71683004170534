import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import alternativesReducer from "./alternativesReducer";
import annotationsReducer from "./annotationsReducer";
import answersReducer from "./answersReducer";
import attachmentsReducer from "./attachmentsReducer";
import bannersReducer from "./bannersReducer";
import certificateReducer from "./certificateReducer";
import classroomsListReducer from "./classroomsListReducer";
import classroomsReducer from "./classroomsReducer";
import commentsReducer from "./commentsReducer";
import configsReducer from "./configsReducer";
import contentBlockReducer from "./contentBlockReducer";
import currentClassroomReducer from "./currentClassroomReducer";
import currentLessonReducer from "./currentLessonReducer";
import currentUserReducer from "./currentUserReducer";
import discardAlternativesReducer from "./discardAlternativesReducer";
import formMessages from "./formMessagesReducer";
import insufficientMedcoinsModalReducer from "./insufficientMedcoinsModalReducer";
import isExpiredUserReducer from "./isExpiredUserReducer";
import isFrialUserReducer from "./isFrialReducer";
import lessonsReducer from "./lessonsReducer";
import lessonsUsersReducer from "./lessonsUsersReducer";
import levelGroupsReducer from "./levelGroupsReducer";
import levelsReducer from "./levelsReducer";
import medtrackReducer from "./medtrackReducer";
import mockModal from "./mockModalReducer";
import mock from "./mockReducer";
import mockTemplatesExamListReducer from "./mockTemplatesExamListReducer";
import mockTemplatesExercisesListReducer from "./mockTemplatesExercisesListReducer";
import mockTemplatesHighlightedListReducer from "./mockTemplatesHighlightedListReducer";
import mockTemplatesReducer from "./mockTemplatesReducer";
import mocksListReducer from "./mocksListReducer";
import mocksReducer from "./mocksReducer";
import {
  notificationsUserReducer,
  notificationsReducer,
} from "./notificationsReducer";
import notificationsUsersReducer from "./notificationsUsersReducer";
import notifier from "./notifierReducer";
import onlyEnrolledStudentsModalReducer from "./onlyEnrolledStudentsModalReducer";
import organizerStatisticsReducer from "./organizerStatisticsReducer";
import organizersReducer from "./organizersReducer";
import purchasedLevelsReducer from "./purchasedLevelsReducer";
import questionsReducer from "./questionsReducer";
import ratingsReducer from "./ratingsReducer";
import reportsReducer from "./reportsReducer";
import reportsUtilsReducer from "./reportsUtilsReducer";
import statisticsReducer from "./statisticsReducer";
import subjectReportListReducer from "./subjectReportListReducer";
import subjectsReducer from "./subjectsReducer";
import surveyUtilsReducer from "./surveyUtilsReducer";
import surveysReducer from "./surveysReducer";
import surveysUsersReducer from "./surveysUsersReducer";
import userSignIgnAttemptFailsReducer from "./userSignInAttemptFailsReducer";
import userTypeReducer from "./userTypeReducer";
import usersReducer from "./usersReducer";

export default (history) =>
  combineReducers({
    entities: combineReducers({
      certificate: certificateReducer,
      annotations: annotationsReducer,
      answers: answersReducer,
      attachments: attachmentsReducer,
      banners: bannersReducer,
      classrooms: classroomsReducer,
      comments: commentsReducer,
      configs: configsReducer,
      levelGroups: levelGroupsReducer,
      levels: levelsReducer,
      lessons: lessonsReducer,
      lessonsUsers: lessonsUsersReducer,
      mocks: mocksReducer,
      mockTemplates: mockTemplatesReducer,
      organizers: organizersReducer,
      questions: questionsReducer,
      alternatives: alternativesReducer,
      users: usersReducer,
      subjects: subjectsReducer,
      ratings: ratingsReducer,
      reports: reportsReducer,
      surveys: surveysReducer,
      surveysUsers: surveysUsersReducer,
      statistics: statisticsReducer,
      notifications: notificationsReducer,
      notificationsForUsers: notificationsUserReducer,
      notificationsUsers: notificationsUsersReducer,
    }),
    isB2BUserExamInProgress: contentBlockReducer,
    mocksList: mocksListReducer,
    mockTemplatesHighlightedList: mockTemplatesHighlightedListReducer,
    mockTemplatesExercisesList: mockTemplatesExercisesListReducer,
    mockTemplatesExamList: mockTemplatesExamListReducer,
    organizerStatistics: organizerStatisticsReducer,
    classroomsList: classroomsListReducer,
    subjectReportList: subjectReportListReducer,
    discardAlternatives: discardAlternativesReducer,
    surveyUtils: surveyUtilsReducer,
    reportsUtils: reportsUtilsReducer,
    currentUserId: currentUserReducer,
    currentClassroomId: currentClassroomReducer,
    currentLessonId: currentLessonReducer,
    router: connectRouter(history),
    form,
    notifier,
    mock,
    mockModal,
    formMessages,
    isFrialUser: isFrialUserReducer,
    isExpiredUser: isExpiredUserReducer,
    userType: userTypeReducer,
    userSignInAttemptFails: userSignIgnAttemptFailsReducer,
    insufficientMedcoinsModal: insufficientMedcoinsModalReducer,
    onlyEnrolledStudentsModal: onlyEnrolledStudentsModalReducer,
    purchasedLevels: purchasedLevelsReducer,
    isMedTrackFirstAccess: medtrackReducer,
  });
