import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

export const Container = styled.div`
  background: ${colors.interface.grey100};
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;
export const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
`;
export const Value = styled.p`
  font-size: 32px;
  font-weight: 600;
`;
export const ValueWrapper = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  gap: 4px;
`;
