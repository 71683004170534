const initialState = {};

export default function UsersReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_FETCHED":
    case "USER_UPDATED":
    case "USERS_FETCHED":
      return { ...state, ...action.entities.users };
    case "DISCOUNT_MEDCOIN":
      const { medcoin, user_id } = action.medcoinData;
      const user = { ...state[user_id], medcoin };
      return { ...state, [user_id]: user };
    default:
      return state;
  }
}
