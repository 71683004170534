import React from "react";
import { Info } from "./styles";

export function Description({ isRevision }) {
  return (
    <Info>
      {isRevision ? (
        "Chegou a hora da sua revisão. Nesse momento vamos priorizar a resolução de questões para sua reta final de preparação para as provas de residência. E o Medtrack te dará todo o direcionamento necessário nessa jornada, criando um direcionamento 100% personalizado para você."
      ) : (
        <>
          O Medtrack é um mentor virtual que orienta exatamente{" "}
          <strong>o que</strong> e <strong>como</strong> você deve estudar para
          potencializar seus resultados. E o melhor: ele se adapta totalmente à
          sua disponibilidade de tempo para estudar a cada semana. Vamos começar
          definindo seu tempo disponível atualmente, mas não se preocupe, pois
          você poderá editar sempre que sua rotina mudar.
        </>
      )}
    </Info>
  );
}
