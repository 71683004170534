import React, { useEffect, useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { medtrackApi } from "../../../../../services/medtrack";
import { colors } from "../../../../components/library/theme";
import { useMedTrack } from "../../hooks/useMedTrack";
import { MedTrackTooltip } from "../MedTrackTooltip";
import { TodoItem } from "../TodoItem";
import { UncheckTodoModal } from "../UncheckTodoModal";
import { MedtrackStatusTag } from "./MedTrackStatusTag";
import { ProgressBar } from "./ProgressBar";
import {
  AccordionContent,
  AccordionTitle,
  BulletPoint,
  Container,
  DateWrapper,
} from "./styles";
import { toast } from "../../../Toast";

const months = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

export function Accordion({ data, updatedTodoGroup, isBases, isRevision }) {
  const isAccordionActive =
    !isRevision || (isRevision && data.tag === "current");
  const [modalOpenId, setModalOpenId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(isAccordionActive);
  const [uncheckTodo, setUncheckTodo] = useState({});
  const [items, setItems] = useState(data.items);
  const { setTodoGroups } = useMedTrack();

  async function onStatusChange(todoId, todoStatus, totalTodoDurationHours) {
    try {
      setIsLoading(true);
      const response = await medtrackApi.put(`/todo-item/check/${todoId}`, {
        isCompleted: todoStatus,
      });

      const todoGroupResponse = response.body.todoGroup;

      const updatedTodoGroupList = updatedTodoGroup.map((todoGroup) => {
        const hasToUpdate = todoGroup.items.some((todo) => todo.id === todoId);

        if (!hasToUpdate) return todoGroup;

        const updatedItems = todoGroup.items.map((todo) => {
          if (todo.id === todoId) {
            return {
              ...todo,
              isCheckedByUser: todoStatus,
              status: todoStatus ? "completed" : "uncompleted",
            };
          }
          return todo;
        });

        setItems(updatedItems);

        const todoGroupWithUpdatedItems = {
          ...todoGroup,
          items: updatedItems,
        };

        const totalProgress = todoGroupWithUpdatedItems.items.reduce(
          (acc, item) => {
            if (item.mocks) {
              const { completed } = item.mocks.reduce(
                (acc, curr) => {
                  const questionsCount = curr.questionsCount ?? 0;
                  const hours = questionsCount * (4 / 60);

                  if (curr.mock?.finishedAt || item.isCheckedByUser) {
                    acc.completed += hours;
                  }
                  acc.total += hours;
                  return acc;
                },
                {
                  completed: 0,
                  total: 0,
                }
              );
              return acc + (completed * 100) / totalTodoDurationHours;
            }

            return item.isCheckedByUser
              ? acc + (item.duration * 100) / totalTodoDurationHours
              : acc;
          },
          0
        );

        const updatedTodoGroup = {
          ...todoGroupWithUpdatedItems,
          tag: todoGroupResponse.tag,
          progress: Math.round(totalProgress),
          isWeekCompleted: todoGroupResponse.progress === 100,
        };

        return updatedTodoGroup;
      });
      setIsLoading(false);
      setTodoGroups(updatedTodoGroupList);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("Erro ao atualizar o status da tarefa");
    }
  }

  function handleUncheck(todoId, todoStatus) {
    setUncheckTodo({ todoId, todoStatus });
  }

  function handleActive() {
    setActive((prev) => !prev);
  }

  function addZero(num) {
    return num.toString().padStart(2, "0");
  }

  function dateFormater() {
    const initial = new Date(data.startDate);
    const end = new Date(data.endDate);

    const initialDay = addZero(initial.getUTCDate());
    const endDay = addZero(end.getUTCDate());

    const initialMonth = months[initial.getUTCMonth()];
    const endMonth = months[end.getUTCMonth()];

    const initialShortMonth = initialMonth.slice(0, 3);
    const endShortMonth = endMonth.slice(0, 3);

    if (initialMonth === endMonth) {
      return `${initialDay} a ${endDay} de ${initialMonth}`;
    }

    return `${initialDay} ${initialShortMonth}. a ${endDay} ${endShortMonth}.`;
  }

  function handleBaseOrder() {
    let order = 0; // número que aparece na frente do item
    const itemsWithOrder = data.items.map((item) => {
      if (item.type.category === "theory") order++;

      return {
        ...item,
        order,
      };
    });

    setItems(itemsWithOrder);
  }

  const totalTodoDurationHours = items.reduce((acc, item) => {
    return acc + item.duration;
  }, 0);

  useEffect(() => {
    if (isBases) return handleBaseOrder();
  }, [data, data.items, isBases]);

  return (
    <Container
      data-open={active}
      isRevision={isRevision}
      isCurrentWeek={data.tag === "current"}
    >
      <AccordionTitle
        active={active}
        onClick={handleActive}
        isRevision={isRevision}
        isCurrentWeek={data.tag === "current"}
      >
        <DateWrapper>
          <BulletPoint
            isRevision={isRevision}
            isCurrentWeek={data.tag === "current"}
          >
            &#x2022;
          </BulletPoint>
          {dateFormater()}
          <MedTrackTooltip
            hasSmallText
            width="158px"
            variant="todoProgress"
            text="Seu progresso semanal"
          >
            <ProgressBar progress={data.progress} />
          </MedTrackTooltip>
        </DateWrapper>

        <MedtrackStatusTag
          tag={data.tag}
          isWeekCompleted={data.isWeekCompleted}
        />

        <IoChevronDown size={20} stroke={colors.medtrack.grey400} />
      </AccordionTitle>

      <AccordionContent active={active}>
        {items?.map((todo, index) => (
          <TodoItem
            handleUncheck={handleUncheck}
            isGroupCompleted={data.progress === 100}
            isBases={isBases}
            isRevision={isRevision}
            hasTopBorder={index === 0}
            onStatusChange={onStatusChange}
            isAccordionOpen={active}
            handleTodoModal={{ setModalOpenId, modalOpenId }}
            key={todo.id}
            todo={todo}
            totalTodoDurationHours={totalTodoDurationHours}
            isLoading={isLoading}
          />
        ))}
      </AccordionContent>

      <UncheckTodoModal
        isOpen={Boolean(uncheckTodo.todoId)}
        onStatusChange={onStatusChange}
        uncheckTodo={uncheckTodo}
        setUncheckTodo={setUncheckTodo}
      />
    </Container>
  );
}
