import styled from "styled-components";
import { colors } from "../../../components/library/theme";
import { EMRButton } from "../../Button";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 46px;
  gap: 24px;

  @media (max-width: 991px) {
    padding: 0px 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;
export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  color: ${colors.interface.grey400};
  line-height: 31.2px;
`;
export const Text = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${colors.interface.grey400};
  line-height: 24px;
  max-width: 500px;
`;

export const BackButton = styled(EMRButton)`
  border: solid 1px ${colors.interface.grey300};
  color: ${colors.interface.grey300};
`;
export const YoutubeVideo = styled.iframe`
  width: 100%;
  height: 291px;
  border: 0;

  @media (max-width: 460px) {
    height: 178px;
  }
`;
