import React, { useContext } from "react";
import { EMRButton } from "../../Button";
import { context } from "../context";
import {
  BackButton,
  ButtonsWrapper,
  Container,
  Text,
  Title,
  YoutubeVideo,
  Content,
} from "./styles";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";

export function Step3() {
  const { setStep, finish, loading, user } = useContext(context);

  function firstName() {
    const names = user.name.split(" ");
    return names[0];
  }

  function goBack() {
    setStep(1);
    trackClickEvents({
      eventName: "Clique em voltar (Tela sugestão de curso step 3)",
    });
  }

  function handleFinish() {
    finish();
    trackClickEvents({
      eventName: "Clique em Acessar plataforma (Tela sugestão de curso step 3)",
    });
  }

  return (
    <Content>
      <Container>
        <Title>Tudo pronto, {firstName()}</Title>
        <Text>
          Assista ao vídeo abaixo para entender o funcionamento da plataforma e
          qual a melhor maneira de utilizá-la.
        </Text>
      </Container>
      <YoutubeVideo
        src={process.env.TRIAL_YOUTUBE_URL}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
      <ButtonsWrapper>
        <BackButton
          disabled={loading}
          onClick={goBack}
          mode="outline"
          fontSize="lg"
          fullSized
        >
          Voltar
        </BackButton>
        <EMRButton
          disabled={loading}
          loading={loading}
          fullSized
          fontSize="lg"
          onClick={handleFinish}
        >
          Acessar plataforma
        </EMRButton>
      </ButtonsWrapper>
    </Content>
  );
}
