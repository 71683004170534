import React from "react";
import { colors } from "../../../components/library/theme";

export function Illustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="218"
      height="205"
      viewBox="0 0 218 205"
      fill="none"
    >
      <g clip-path="url(#clip0_472_2221)">
        <path
          d="M-29 202.748C-29 203.164 -28.6666 203.497 -28.2515 203.497H300.252C300.667 203.497 301 203.164 301 202.748C301 202.333 300.667 202 300.252 202H-28.2515C-28.6666 202 -29 202.333 -29 202.748Z"
          fill="#C2CED6"
        />
        <path
          d="M41.0647 24.3018L43.6115 35.7913L38.1944 51.7837L29.4668 34.6012L30.6778 24.6329L41.0647 24.3018Z"
          fill="#FDB4B4"
        />
        <path
          d="M44.0849 35.1965H29.0156V53.1973H44.0849V35.1965Z"
          fill={colors.brand.pure}
        />
        <path
          d="M37.887 30.0899C43.5707 30.0899 48.1782 25.5347 48.1782 19.9156C48.1782 14.2964 43.5707 9.74121 37.887 9.74121C32.2033 9.74121 27.5957 14.2964 27.5957 19.9156C27.5957 25.5347 32.2033 30.0899 37.887 30.0899Z"
          fill="#FDB4B4"
        />
        <path
          d="M28.9316 27.4812C28.9316 27.4812 21.2605 13.4748 28.1769 6.73462C35.0933 -0.00561142 40.6455 4.43578 40.6455 4.43578L43.816 4.3091C43.816 4.3091 44.4773 6.77589 45.5435 5.44051C46.6097 4.10513 48.5365 8.9684 48.5365 8.9684C52.1125 15.3356 39.7156 16.7619 39.7156 16.7619C39.7156 16.7619 40.6108 19.5811 39.7009 20.1715C38.791 20.7619 36.36 22.9829 36.36 22.9829L34.184 24.6077L32.8909 22.6598C32.8909 22.6598 31.5402 21.6057 30.8578 22.0485C30.1753 22.4913 31.114 28.3174 31.114 28.3174L28.9316 27.4812Z"
          fill="#2E2E41"
        />
        <path
          d="M16.585 130.523L12.6504 188.548L22.323 188.872L35.1105 131.01L16.585 130.523Z"
          fill="#363E46"
        />
        <path
          d="M40.0264 130.2L36.0918 188.225L45.7644 188.549L58.5519 130.686L40.0264 130.2Z"
          fill="#363E46"
        />
        <path
          d="M14.2154 143.78L19.3646 80.5834L11.4681 47.6752C10.7873 42.7834 13.905 38.1547 18.7167 36.8926L26.4435 34.8659L29.8635 31.4846L37.6424 48.4679L42.5007 31.4976L46.2897 34.2219L57.5272 39.1234L57.5221 39.2382L55.7196 78.991L59.7432 143.218L59.5647 143.22L14.2154 143.78Z"
          fill="#E8E9EA"
        />
        <path
          d="M45.1455 187.889L47.9462 196.155C47.9462 196.155 58.9033 197.628 59.0738 200.981C59.2443 204.335 44.1825 202.393 35.363 202.832C26.5434 203.27 33.885 193.828 33.885 193.828L34.8248 188.402L45.1455 187.889Z"
          fill="#363E46"
        />
        <path
          d="M22.2724 187.295L25.0731 195.561C25.0731 195.561 36.0303 197.034 36.2008 200.387C36.3713 203.741 21.3095 201.8 12.4899 202.238C3.67035 202.676 11.0119 193.234 11.0119 193.234L11.9518 187.808L22.2724 187.295Z"
          fill="#363E46"
        />
        <path
          d="M8.61007 110.219C8.4913 108.589 8.76915 106.955 9.42036 105.453L7.33008 88.5006L15.5183 87.7532L16.7568 105.03C17.5796 106.446 18.0474 108.037 18.121 109.669C18.3618 113.751 16.4279 117.181 13.8012 117.333C11.1745 117.484 8.85024 114.299 8.61007 110.219Z"
          fill="#FDB4B4"
        />
        <path
          d="M60.4701 112.706C60.3693 111.075 60.6652 109.444 61.3329 107.95L59.4297 90.9756L67.6256 90.3165L68.6735 107.606C69.4807 109.031 69.931 110.626 69.9866 112.259C70.1823 116.343 68.2107 119.753 65.5825 119.876C62.9543 119.999 60.6653 116.789 60.4701 112.706Z"
          fill="#FDB4B4"
        />
        <path
          d="M7.29511 103.42L7.26741 103.273L3 80.6367L8.04239 43.7752C8.49232 40.4843 11.4647 38.1136 14.8165 38.3786C16.5669 38.5171 18.1374 39.3379 19.2391 40.6901C20.3406 42.0422 20.8173 43.7343 20.5811 45.4547L16.786 73.1003L19.1754 102.67L7.29511 103.42Z"
          fill="#E8E9EA"
        />
        <path
          d="M57.9406 102.888L49.3608 46.2673C48.9149 44.607 49.1759 42.8696 50.0996 41.3932C51.0234 39.9167 52.4791 38.91 54.1989 38.5583C57.489 37.8856 60.7378 39.8739 61.5965 43.0842L69.839 102.176L57.9406 102.888Z"
          fill="#E8E9EA"
        />
        <path
          d="M37.8951 53.6433C38.4769 53.6433 38.9485 53.177 38.9485 52.6019C38.9485 52.0268 38.4769 51.5605 37.8951 51.5605C37.3134 51.5605 36.8418 52.0268 36.8418 52.6019C36.8418 53.177 37.3134 53.6433 37.8951 53.6433Z"
          fill="#D6D7D8"
        />
        <path
          d="M37.8951 61.5291C38.4769 61.5291 38.9485 61.0629 38.9485 60.4878C38.9485 59.9127 38.4769 59.4464 37.8951 59.4464C37.3134 59.4464 36.8418 59.9127 36.8418 60.4878C36.8418 61.0629 37.3134 61.5291 37.8951 61.5291Z"
          fill="#D6D7D8"
        />
        <path
          d="M37.8951 69.4138C38.4769 69.4138 38.9485 68.9475 38.9485 68.3724C38.9485 67.7973 38.4769 67.3311 37.8951 67.3311C37.3134 67.3311 36.8418 67.7973 36.8418 68.3724C36.8418 68.9475 37.3134 69.4138 37.8951 69.4138Z"
          fill="#D6D7D8"
        />
        <path
          d="M37.8951 77.2976C38.4769 77.2976 38.9485 76.8313 38.9485 76.2562C38.9485 75.6811 38.4769 75.2148 37.8951 75.2148C37.3134 75.2148 36.8418 75.6811 36.8418 76.2562C36.8418 76.8313 37.3134 77.2976 37.8951 77.2976Z"
          fill="#D6D7D8"
        />
        <path
          d="M37.8951 85.1833C38.4769 85.1833 38.9485 84.7171 38.9485 84.1419C38.9485 83.5668 38.4769 83.1006 37.8951 83.1006C37.3134 83.1006 36.8418 83.5668 36.8418 84.1419C36.8418 84.7171 37.3134 85.1833 37.8951 85.1833Z"
          fill="#D6D7D8"
        />
        <path
          d="M37.8951 93.0671C38.4769 93.0671 38.9485 92.6009 38.9485 92.0257C38.9485 91.4506 38.4769 90.9844 37.8951 90.9844C37.3134 90.9844 36.8418 91.4506 36.8418 92.0257C36.8418 92.6009 37.3134 93.0671 37.8951 93.0671Z"
          fill="#D6D7D8"
        />
        <path
          d="M37.8951 100.951C38.4769 100.951 38.9485 100.485 38.9485 99.9095C38.9485 99.3344 38.4769 98.8682 37.8951 98.8682C37.3134 98.8682 36.8418 99.3344 36.8418 99.9095C36.8418 100.485 37.3134 100.951 37.8951 100.951Z"
          fill="#D6D7D8"
        />
        <path
          d="M37.8951 108.837C38.4769 108.837 38.9485 108.37 38.9485 107.795C38.9485 107.22 38.4769 106.754 37.8951 106.754C37.3134 106.754 36.8418 107.22 36.8418 107.795C36.8418 108.37 37.3134 108.837 37.8951 108.837Z"
          fill="#D6D7D8"
        />
        <path
          d="M47.5515 47.3158L46.8995 47.1279C48.258 42.5225 45.3615 33.7951 45.332 33.7089L46.1471 34.3163C46.2715 34.6769 48.9698 42.5088 47.5515 47.3158Z"
          fill="#3F3C57"
        />
        <path
          d="M24.6312 48.1339L25.2833 47.946C23.9247 43.3406 26.8541 34.7463 26.8836 34.66L26.1861 35.122C26.0617 35.4825 23.2129 43.3268 24.6312 48.1339Z"
          fill="#3F3C57"
        />
        <path
          d="M47.2261 52.6061C48.7931 52.6061 50.0635 51.3501 50.0635 49.8009C50.0635 48.2516 48.7931 46.9957 47.2261 46.9957C45.659 46.9957 44.3887 48.2516 44.3887 49.8009C44.3887 51.3501 45.659 52.6061 47.2261 52.6061Z"
          fill="#3F3C57"
        />
        <path
          d="M27.654 65.3838H25.9333C25.7456 65.3838 25.5936 65.2335 25.5936 65.048C25.5936 64.8626 25.7456 64.7123 25.9333 64.7123H27.654C28.1283 64.7123 28.5738 64.5296 28.9088 64.1978C29.2435 63.8661 29.4275 63.4252 29.4266 62.9566L29.4057 52.4779C29.4057 50.2016 27.533 48.3502 25.2311 48.3502C22.9292 48.3502 21.0562 50.2016 21.0562 52.4773L21.0771 62.2854C21.0791 63.2422 21.8682 64.0266 22.836 64.034L24.0172 64.0428C24.2046 64.0443 24.3555 64.1956 24.3541 64.3812C24.3528 64.5657 24.2009 64.7144 24.0145 64.7144H24.0119L22.8307 64.7055C21.4921 64.6954 20.4005 63.6101 20.3978 62.2867L20.377 52.4779C20.377 49.8313 22.5544 47.6786 25.2311 47.6786C27.9074 47.6786 30.0849 49.8313 30.0849 52.4773L30.1058 62.9553C30.1072 63.6036 29.8528 64.2133 29.3894 64.6721C28.9264 65.131 28.3098 65.3838 27.654 65.3838Z"
          fill="#3F3C57"
        />
        <path
          d="M24.0144 65.5685C24.6792 65.5685 25.2182 65.0357 25.2182 64.3784C25.2182 63.7211 24.6792 63.1882 24.0144 63.1882C23.3495 63.1882 22.8105 63.7211 22.8105 64.3784C22.8105 65.0357 23.3495 65.5685 24.0144 65.5685Z"
          fill="#3F3C57"
        />
        <path
          d="M25.9343 66.2395C26.5991 66.2395 27.1381 65.7067 27.1381 65.0494C27.1381 64.3921 26.5991 63.8593 25.9343 63.8593C25.2694 63.8593 24.7305 64.3921 24.7305 65.0494C24.7305 65.7067 25.2694 66.2395 25.9343 66.2395Z"
          fill="#3F3C57"
        />
        <path
          d="M172.701 17.9567L186.491 10.7666V31.3097H170.543L172.701 17.9567Z"
          fill="#2E2E41"
        />
        <path
          d="M189.448 22.5362L190.721 32.6216L181.549 50.006L175.855 33.8257L178.532 22.2012L189.448 22.5362Z"
          fill="#FDB4B4"
        />
        <path
          d="M191.196 33.2246H175.359V51.4369H191.196V33.2246Z"
          fill={colors.brand.pure}
        />
        <path
          d="M204.258 129.672L208.393 188.378L198.228 188.706L184.789 130.164L204.258 129.672Z"
          fill="#363E46"
        />
        <path
          d="M179.621 129.345L183.757 188.051L173.591 188.379L160.152 129.837L179.621 129.345Z"
          fill="#363E46"
        />
        <path
          d="M215 142.123L214.758 142.123L150 141.921L150.058 141.703L166.707 78.9203L162.537 47.236C161.746 41.2322 165.123 35.5573 170.939 33.1152L173.039 32.2336L177.028 29.4726L182.131 46.6348L190.303 29.4592L193.903 32.885L202.021 34.9352C207.082 36.2128 210.36 40.8988 209.647 45.835L209.638 45.8693L197.24 79.54L215 142.123Z"
          fill="#E8E9EA"
        />
        <path
          d="M174.245 187.711L185.091 188.23L186.079 193.72C186.079 193.72 193.794 203.273 184.526 202.83C175.257 202.386 159.428 204.35 159.607 200.957C159.786 197.564 171.301 196.074 171.301 196.074L174.245 187.711Z"
          fill="#363E46"
        />
        <path
          d="M198.282 187.109L209.128 187.628L210.116 193.118C210.116 193.118 217.832 202.671 208.563 202.228C199.294 201.785 183.465 203.749 183.644 200.356C183.823 196.963 195.339 195.473 195.339 195.473L198.282 187.109Z"
          fill="#363E46"
        />
        <path
          d="M208.216 108.525C207.964 112.653 205.521 115.876 202.76 115.722C200 115.569 197.967 112.098 198.221 107.968C198.298 106.317 198.789 104.708 199.654 103.275L200.956 85.7949L209.561 86.5511L207.364 103.703C208.049 105.222 208.341 106.876 208.216 108.525Z"
          fill="#FDB4B4"
        />
        <path
          d="M163.995 106.956C163.65 111.078 161.136 114.25 158.38 114.041C155.623 113.832 153.669 110.321 154.014 106.197C154.128 104.548 154.656 102.949 155.553 101.533L157.245 84.084L165.831 85.0143L163.251 102.118C163.902 103.651 164.157 105.309 163.995 106.956Z"
          fill="#FDB4B4"
        />
        <path
          d="M209.599 101.653L197.101 100.894L197.116 100.716L199.613 70.9712L195.625 43.0014C195.376 41.2592 195.877 39.5456 197.036 38.1764C198.195 36.8069 199.847 35.9757 201.689 35.8354C205.214 35.5708 208.341 37.968 208.814 41.3007L214.114 78.5962L214.108 78.624L209.599 101.653Z"
          fill="#E8E9EA"
        />
        <path
          d="M166.072 100.514L153.555 99.7937L153.582 99.6056L162.214 40.021C163.122 36.7484 166.541 34.7355 170 35.4164C171.809 35.7725 173.34 36.792 174.312 38.2874C175.284 39.7827 175.559 41.542 175.085 43.2416L166.072 100.514Z"
          fill="#E8E9EA"
        />
        <path
          d="M181.865 51.8884C182.476 51.8884 182.972 51.4167 182.972 50.8348C182.972 50.253 182.476 49.7812 181.865 49.7812C181.253 49.7812 180.758 50.253 180.758 50.8348C180.758 51.4167 181.253 51.8884 181.865 51.8884Z"
          fill="#D6D7D8"
        />
        <path
          d="M181.865 59.866C182.476 59.866 182.972 59.3943 182.972 58.8124C182.972 58.2305 182.476 57.7588 181.865 57.7588C181.253 57.7588 180.758 58.2305 180.758 58.8124C180.758 59.3943 181.253 59.866 181.865 59.866Z"
          fill="#D6D7D8"
        />
        <path
          d="M181.865 67.8435C182.476 67.8435 182.972 67.3718 182.972 66.7899C182.972 66.208 182.476 65.7363 181.865 65.7363C181.253 65.7363 180.758 66.208 180.758 66.7899C180.758 67.3718 181.253 67.8435 181.865 67.8435Z"
          fill="#D6D7D8"
        />
        <path
          d="M181.865 75.822C182.476 75.822 182.972 75.3503 182.972 74.7684C182.972 74.1866 182.476 73.7148 181.865 73.7148C181.253 73.7148 180.758 74.1866 180.758 74.7684C180.758 75.3503 181.253 75.822 181.865 75.822Z"
          fill="#D6D7D8"
        />
        <path
          d="M181.865 83.7967C182.476 83.7967 182.972 83.3249 182.972 82.7431C182.972 82.1612 182.476 81.6895 181.865 81.6895C181.253 81.6895 180.758 82.1612 180.758 82.7431C180.758 83.3249 181.253 83.7967 181.865 83.7967Z"
          fill="#D6D7D8"
        />
        <path
          d="M181.865 91.7752C182.476 91.7752 182.972 91.3035 182.972 90.7216C182.972 90.1397 182.476 89.668 181.865 89.668C181.253 89.668 180.758 90.1397 180.758 90.7216C180.758 91.3035 181.253 91.7752 181.865 91.7752Z"
          fill="#D6D7D8"
        />
        <path
          d="M181.865 99.7517C182.476 99.7517 182.972 99.28 182.972 98.6981C182.972 98.1162 182.476 97.6445 181.865 97.6445C181.253 97.6445 180.758 98.1162 180.758 98.6981C180.758 99.28 181.253 99.7517 181.865 99.7517Z"
          fill="#D6D7D8"
        />
        <path
          d="M181.865 107.73C182.476 107.73 182.972 107.259 182.972 106.677C182.972 106.095 182.476 105.623 181.865 105.623C181.253 105.623 180.758 106.095 180.758 106.677C180.758 107.259 181.253 107.73 181.865 107.73Z"
          fill="#D6D7D8"
        />
        <path
          d="M183.546 27.6834C189.264 27.6834 193.9 23.2712 193.9 17.8285C193.9 12.3858 189.264 7.97363 183.546 7.97363C177.827 7.97363 173.191 12.3858 173.191 17.8285C173.191 23.2712 177.827 27.6834 183.546 27.6834Z"
          fill="#FDB4B4"
        />
        <path
          d="M188.973 6.32539C189.469 7.28582 188.682 8.48551 187.641 8.8893C186.6 9.29309 185.423 9.12948 184.313 8.9614C183.202 8.79332 182.024 8.63394 180.986 9.04488C179.643 9.57659 178.885 10.9104 178.233 12.1481C177.014 14.4587 175.796 16.7693 174.578 19.0799C173.983 20.2089 173.374 21.3885 173.363 22.6516C173.342 24.97 175.274 26.8227 176.314 28.9194C177.58 31.4723 177.475 34.6008 176.042 37.0721C174.608 39.5433 171.882 41.2943 168.938 41.6349C168.163 41.7245 167.343 41.7148 166.647 41.3798C165.95 41.0449 165.419 40.3134 165.548 39.5809C165.8 38.1482 168.1 37.8134 168.523 36.4175C168.743 35.6888 168.353 34.9106 167.826 34.3414C167.3 33.7722 166.634 33.3282 166.132 32.7395C164.974 31.382 164.897 29.2864 165.952 27.855C166.572 27.0138 167.531 26.3888 167.932 25.4346C168.274 24.6213 168.15 23.7071 168.02 22.8399C167.854 21.7255 167.688 20.6111 167.522 19.4968C167.328 18.1967 167.136 16.8499 167.52 15.5884C168.398 12.6977 171.828 11.34 173.839 9.018C174.774 7.93899 175.402 6.64131 176.361 5.58172C177.97 3.80358 180.503 2.82266 182.959 3.02657C185.416 3.23048 187.732 4.61398 188.993 6.6304"
          fill="#2E2E41"
        />
        <path
          d="M187.336 6.50781C186.831 6.67999 186.643 7.30709 186.788 7.79928C186.934 8.29147 187.315 8.68254 187.664 9.07128C191.612 13.4679 192.529 20.1183 189.908 25.3358C189.211 26.7236 188.282 28.0297 187.906 29.5258C187.633 30.6143 187.648 31.8084 187.013 32.7508C186.367 33.7102 185.087 34.3531 184.96 35.4862C184.879 36.2134 185.345 36.9197 185.969 37.346C186.593 37.7724 187.356 37.9674 188.106 38.1265C190.055 38.5399 192.048 38.7656 194.045 38.799C194.696 38.8099 195.367 38.7965 195.965 38.5496C196.562 38.3027 197.071 37.7673 197.078 37.1477C197.084 36.6607 196.788 36.2115 196.751 35.7258C196.638 34.2704 198.696 33.4962 199.096 32.0877C199.36 31.1616 198.841 30.2087 198.288 29.4067C197.734 28.6046 197.103 27.7624 197.117 26.8031C197.13 25.8847 197.735 25.0759 197.971 24.1852C198.296 22.9531 197.896 21.6644 197.5 20.4514C197.163 19.4201 196.825 18.3888 196.488 17.3575C196.13 16.2612 195.771 15.1649 195.413 14.0686C194.787 12.1547 194.13 10.1853 192.776 8.65029C191.421 7.11524 189.177 6.10912 187.177 6.73586"
          fill="#2E2E41"
        />
        <path
          d="M194.395 44.6962L193.685 44.4993C195.164 39.6742 191.975 30.67 191.943 30.5796L192.643 30.3525C192.778 30.7303 195.938 39.6598 194.395 44.6962Z"
          fill="#3F3C57"
        />
        <path
          d="M194.039 50.2383C195.744 50.2383 197.127 48.9225 197.127 47.2993C197.127 45.6762 195.744 44.3604 194.039 44.3604C192.334 44.3604 190.951 45.6762 190.951 47.2993C190.951 48.9225 192.334 50.2383 194.039 50.2383Z"
          fill="#3F3C57"
        />
        <path
          d="M173.75 57.7041H171.877C171.673 57.7041 171.507 57.5466 171.507 57.3523C171.507 57.158 171.673 57.0005 171.877 57.0005H173.75C174.266 57.0005 174.751 56.8091 175.115 56.4614C175.479 56.1139 175.68 55.652 175.679 55.1611L175.656 44.1826C175.656 41.7976 173.618 39.8579 171.113 39.8579C168.608 39.8579 166.569 41.7976 166.569 44.1819L166.592 54.4578C166.594 55.4603 167.453 56.2821 168.506 56.2898L169.792 56.2991C169.996 56.3006 170.16 56.4592 170.158 56.6537C170.157 56.8469 169.992 57.0027 169.789 57.0027H169.786L168.5 56.9934C167.044 56.9828 165.856 55.8458 165.853 54.4592L165.83 44.1826C165.83 41.4097 168.2 39.1543 171.113 39.1543C174.025 39.1543 176.395 41.4097 176.395 44.1819L176.418 55.1597C176.419 55.8389 176.142 56.4778 175.638 56.9584C175.134 57.4392 174.463 57.7041 173.75 57.7041Z"
          fill="#3F3C57"
        />
        <path
          d="M169.789 57.8981C170.512 57.8981 171.099 57.3398 171.099 56.6512C171.099 55.9626 170.512 55.4043 169.789 55.4043C169.065 55.4043 168.479 55.9626 168.479 56.6512C168.479 57.3398 169.065 57.8981 169.789 57.8981Z"
          fill="#3F3C57"
        />
        <path
          d="M171.878 58.5993C172.602 58.5993 173.189 58.041 173.189 57.3524C173.189 56.6637 172.602 56.1055 171.878 56.1055C171.155 56.1055 170.568 56.6637 170.568 57.3524C170.568 58.041 171.155 58.5993 171.878 58.5993Z"
          fill="#3F3C57"
        />
        <path
          d="M109 170.304C142.137 170.304 169 143.441 169 110.304C169 77.1666 142.137 50.3037 109 50.3037C75.8629 50.3037 49 77.1666 49 110.304C49 143.441 75.8629 170.304 109 170.304Z"
          fill={colors.brand.pure}
        />
        <path
          d="M109.001 83.4441C113.239 83.4441 116.675 86.88 116.675 91.1185V106.467H101.326V91.1185C101.326 86.88 104.762 83.4441 109.001 83.4441ZM120.512 106.467V91.1185C120.512 84.7608 115.358 79.6068 109.001 79.6068C102.643 79.6068 97.4889 84.7608 97.4889 91.1185V106.467C93.2504 106.467 89.8145 109.903 89.8145 114.142V133.328C89.8145 137.566 93.2504 141.002 97.4889 141.002H120.512C124.751 141.002 128.187 137.566 128.187 133.328V114.142C128.187 109.903 124.751 106.467 120.512 106.467ZM97.4889 110.304H120.512C122.631 110.304 124.349 112.022 124.349 114.142V133.328C124.349 135.447 122.631 137.165 120.512 137.165H97.4889C95.3697 137.165 93.6517 135.447 93.6517 133.328V114.142C93.6517 112.022 95.3697 110.304 97.4889 110.304Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_472_2221">
          <rect width="218" height="205" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
