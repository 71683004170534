import styled from "styled-components";
import { colors } from "../../../../../components/library/theme";
import { Spinner } from "../../../../Spinner";

export const ArrowIcon = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: ${colors.interface.grey200};
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;

  @media (max-width: 991px) {
    height: 24px;
    width: 24px;
    min-width: 24px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const CompletedTaskButton = styled.button`
  cursor: pointer;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: ${colors.medtrack.green200};
  opacity: 0.4;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;

  @media (max-width: 991px) {
    height: 24px;
    width: 24px;
    min-width: 24px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const ButtonSpinner = styled(Spinner)`
    width: 32px;
    height: 32px;


  @media (max-width: 991px) {
      width: 24px;
      height: 24px;
  }
`;
