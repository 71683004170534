import React from "react";
import { IoAlertCircle, IoArrowForward, IoBulb } from "react-icons/io5";
import { colors } from "../../../../components/library/theme";
import { Container, InfoLink, InfoWrapper, LinkWrapper } from "./styles";

export const infoboxVariants = {
  lightGreen: {
    bgColor: colors.medtrack.green000,
    borderColor: colors.medtrack.green100,
    iconAndLinkColor: colors.medtrack.green200,
    icon: (color) => <IoBulb size={24} color={color} />
  },
  lightGrey: {
    bgColor: colors.medtrack.grey000,
    borderColor: colors.medtrack.grey100,
    iconAndLinkColor: colors.medtrack.grey300,
    icon: (color) => <IoAlertCircle size={24} color={color} />
  }
};

export function Infobox({
  variant,
  children,
  linkTo,
  linkText,
  shouldOpenInAnotherTab,
  ...rest
}) {
  const { iconAndLinkColor } = infoboxVariants[variant];
  const hasLink = Boolean(linkTo) && Boolean(linkText);
  const link = shouldOpenInAnotherTab ? { pathname: linkTo } : linkTo;
  const linkTarget = shouldOpenInAnotherTab ? "_blank" : "_self";

  function handleClick() {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(linkTo);
    }
  }

  return (
    <Container variant={variant} {...rest}>
      {infoboxVariants[variant].icon(iconAndLinkColor)}

      <InfoWrapper>
        <span>{children}</span>

        {hasLink && (
          <LinkWrapper>
            {window?.ReactNativeWebView ? (
              <InfoLink variant={variant} onClick={handleClick}>
                {linkText}
              </InfoLink>
            ) : (
              <InfoLink variant={variant} to={link} target={linkTarget}>
                {linkText}
              </InfoLink>
            )}
            <IoArrowForward size={20} color={iconAndLinkColor} />
          </LinkWrapper>
        )}
      </InfoWrapper>
    </Container>
  );
}
