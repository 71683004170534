import styled from "styled-components";
import { colors } from '../../components/library/theme';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  padding: 2px 4px;  
  height: 41px;
  align-items: center;

  @media(max-width: 992px) {
    width: 100%;
    height: 96px;
    flex-direction: column;
    background-color: #EDF0F2;
    position: fixed;
    gap: 8px;
    padding: 8px 0;
    top: 70px;
    left: 0;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  gap: 4px;
`
export const Counter = styled.div`
  position: relative;
  height: 41px;
  width: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 16px;
  flex-direction: column;
  min-width: 40px;

  p {
    color: #E64444;
    font-size: 16px;
    position: absolute;
    top: 0;
    font-weight: 600;
  }

  span {
    color: #E64444;
    font-size: 10px;
    position: absolute;
    bottom: 0;
  }

  @media(max-width: 992px) {
    border: 1px solid #C2CED6;
  }
`
export const Button = styled.button`
  background-color: ${colors.brand.pure};
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  height: 41px;
  font-weight: 600;
  width: 177px;
  transition: colors 300ms;

  @media(max-width: 992px) {
    height: 32px;
  }

  &:hover {
    background: ${colors.brand.dark100};
  }
`