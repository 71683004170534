import styled from "styled-components";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import { trackCreateNewMockTemplateEvent } from "../../../services/mixpanel/events/trackCreateNewMockTemplateEvent";
import { EMRButton } from "../../componentsV2/Button";
import MedcoinIndicator from "../../componentsV2/medcoins/medcoinIndicator";
import { fetchMedtrackMocks } from "../../processes/mockProccess";
import { getCurrentUser, isExpiredUser } from "../../selectors/userSelectors";
import Page from "../library/Page";
import MockTemplatesExercisesContainer from "./MockTemplatesExercisesContainer";
import { connect } from "react-redux";
import { colors } from "../library/theme";
import MockTemplatesHighlightedContainer from "./MockTemplatesHighlightedContainer";
import MockTemplatesIntelligentContainer from "./MockTemplatesIntelligentContainer";
import MockTemplatesMedtrackContainer from "./MockTemplatesMedtrackContainer";
import MocksContainer from "./MocksContainer";
import Link from "../library/Link";
import { IoHeartSharp } from "react-icons/io5";

export const TabWrapper = styled.div`
  @media (max-width: 600px) {
    overflow-y: clip;

    .ui.green.pointing.secondary.menu {
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: clip;
    }
    .ui.green.pointing.secondary.menu ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-left: auto;
  width: fit-content;
  @media (max-width: 767px) {
    margin: none;
    width: 100%;

    > button {
      width: 100%;
    }
  }
`;

const ButtonFavoriteQuestions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  gap: 8px;
  padding: 0px 24px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 4px;
  background-color: #e1e8ed;
  cursor: pointer;
  color: #606a71;

  > svg {
    fill: #35bd78;
  }

  &:hover {
    background-color: #c2ced6;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 767px) {
    gap: 16px;
    flex-direction: column-reverse;
  }
`;
const menuOptions = {
  color: colors.semantic,
  secondary: true,
  pointing: true,
};
const panes = [
  {
    menuItem: "Meus Simulados",
    tabName: "my",
    render: () => (
      <Tab.Pane as="div">
        <MocksContainer />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Simulados Gerais",
    tabName: "general",
    render: () => (
      <Tab.Pane as="div">
        <MockTemplatesHighlightedContainer />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Simulados de Fixação",
    tabName: "fixation",
    render: () => (
      <Tab.Pane as="div">
        <MockTemplatesExercisesContainer />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Simulados Inteligentes",
    tabName: "intelligent",
    render: () => (
      <Tab.Pane as="div">
        <MockTemplatesIntelligentContainer />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Simulados Medtrack",
    tabName: "medtrack",
    render: () => (
      <Tab.Pane as="div">
        <MockTemplatesMedtrackContainer />
      </Tab.Pane>
    ),
  },
];

function MockPage({
  children,
  history,
  user,
  isFrialUser,
  expiredUser,
  dispatch,
  medtrackEnabled,
}) {
  const reactRouterHistory = useHistory();
  const [isMedtrackEnabled, setIsMedtrackEnabled] = useState(medtrackEnabled);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tab = urlParams.get("tab");

  const tabs = {
    my: 0,
    general: 1,
    fixation: 2,
    intelligent: 3,
    medtrack: 4,
  };

  if (!medtrackEnabled) {
    delete tabs.medtrack;
  }

  const panesToRender = panes.filter((p, i) => i !== 4 || medtrackEnabled);

  const initialActivePane = tabs[tab] ?? 0;

  const [activePane, setActivePane] = useState(initialActivePane);

  const handleTabChange = useCallback((e, { activeIndex }) => {
    const tabName = panes[activeIndex].menuItem;

    dispatch({
      type: "SET_ACTIVE_MOCK_TAB_NAME",
      activeMockTabName: tabName,
    });

    reactRouterHistory.push({
      search: `?tab=${panes[activeIndex].tabName}`,
    });
    setActivePane(activeIndex);
  }, []);

  function handleClick() {
    if (user.medcoin < 5 && isFrialUser) {
      dispatch({ type: "SHOW_INSUFFICIENT_MEDCOINS_MODAL" });
      return;
    }

    trackCreateNewMockTemplateEvent({
      eventType: "newMockModal",
      mockTemplateType: panes[activePane].menuItem,
      isFrialUser,
    });
    reactRouterHistory.push("/mocks/new");
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mockType = urlParams.get("type");
    fetchMedtrackMocks({ medtrackType: mockType }).then((mocks) => {
      const shouldShow = mocks.some((mock) => mock.progress < 1);

      if (!shouldShow) {
        setIsMedtrackEnabled(false);
        delete tabs.medtrack;
      }
    });
    if (mockType === "smart") setActivePane(3);
  }, []);

  if (expiredUser) return null;

  return (
    <Page
      title="Simulados"
      pageIsIntelligent={activePane == 3 ? true : false}
      history={history}
      action={
        <ButtonContainer>
          {/* <Link to="/favorite-questions">
            <ButtonFavoriteQuestions>
              <IoHeartSharp size={24} />
              Questões favoritas
            </ButtonFavoriteQuestions>
          </Link> */}
          {activePane === 0 && (
            <ButtonWrapper>
              <EMRButton
                onClick={() => handleClick()}
                fontSize="lg"
                mediumWeight
              >
                + Criar Novo Simulado
              </EMRButton>
              <MedcoinIndicator variant="large">5 Medcoins</MedcoinIndicator>
            </ButtonWrapper>
          )}
        </ButtonContainer>
      }
    >
      <TabWrapper>
        <Tab
          menu={menuOptions}
          panes={panesToRender}
          activeIndex={activePane}
          onTabChange={handleTabChange}
        />
      </TabWrapper>
      {children}
    </Page>
  );
}

function mapStateToProps(state, ownProps) {
  const expiredUser = isExpiredUser(state);
  const isFrialUser = state?.isFrialUser;
  const user = getCurrentUser(state);

  return {
    expiredUser,
    isFrialUser,
    user,
    medtrackEnabled: state.isMedTrackFirstAccess.medtrackEnabled,
    ...ownProps,
  };
}

export default connect(mapStateToProps)(MockPage);
