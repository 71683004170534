import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const StyledTooltip = styled.div`
  pointer-events: none;
  border-radius: 4px;
  padding: 8px;
  color: ${colors.interface.grey400};
  border: solid 1px ${colors.interface.grey200};
  background-color: ${colors.interface.grey100};
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  text-transform: initial;

  svg {
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
  }
`;

const variants = {
  large: `width: 48px;
          height: 48px;
          min-width: 48px;
          
          svg {
            min-width: 28px;
            width: 28px;
            height: 28px;
          }`,
  medium: `width: 40px;
          height: 40px;
          min-width: 40px;
          
          svg {
            min-width: 24px;
            width: 24px;
            height: 24px;
          }`,
  default: `width: 34px;
            height: 34px;
            min-width: 34px;
            
            svg {
              min-width: 20px;
              width: 20px;
              height: 20px;
            }
  `
};

export const MedcoinStamp = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${colors.brand.pure};
  border-radius: 100%;
  background-color: ${(props) => (props.isLevelPurchased ? colors.brand.pure : colors.brand.light000)};

  ${(props) => variants[props.variant || "default"]}
`;
