import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Lesson from "./Lesson";
import {
  getCurrentLesson,
  getNextLesson,
  getPrevLesson,
  makeIsLessonMock,
} from "../../selectors/lessonSelectors";
import { getMockFromMockTemplate } from "../../selectors/mockSelectors";
import { makeIsWatched } from "../../selectors/lessonsUserSelectors";
import { createOrUpdateLessonsUser } from "../../processes/lessonUsersProcesses";
import { currentClassroom } from "../../selectors/classroomSelectors";
import getUrlParams from "../modalReview/getUrlParams";
import { getCurrentUser, isExpiredUser } from "../../selectors/userSelectors";
import { getLevelGroupByClassroom } from "../../selectors/levelGroupSelectors";
import { getLevelById } from "../../selectors/levelSelectors";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";
function LessonContainer(props) {
  const { dispatch, lesson, currentClassroomId } = props;

  const markAsWatchedHandler = useCallback(
    () => createOrUpdateLessonsUser(dispatch, currentClassroomId, lesson.id, 1),
    [lesson, currentClassroomId, dispatch]
  );

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [lesson?.id]);

  return <Lesson markAsWatchedHandler={markAsWatchedHandler} {...props} />;
}

function getObjectByLessonId(data, lessonId) {
  for (const obj of Object.values(data)) {
    if (obj.lessonId === lessonId) {
      return obj;
    }
  }
  return null; // Retorna null caso não encontre o objeto com o lessonId fornecido
}

function mapStateToProps(state, ownProps) {
  const numberOfRatings = Object.keys(state.entities.ratings).length;
  const { id } = ownProps.match.params;
  const lesson = getCurrentLesson(state, id);
  const lassonWatchedState = getObjectByLessonId(
    state.entities.lessonsUsers,
    getUrlParams().lessonId
  );
  const mock = getMockFromMockTemplate(state, lesson?.mockTemplateId);
  const mockTemplate =
    ownProps.mock && getMockTemplateById(state, mock.mockTemplateId);
  const isPreExam = mockTemplate ? mockTemplate.preExam : false;
  const isLessonMock = makeIsLessonMock(state, lesson?.id);
  const canExportMock =
    isLessonMock && mock && (!mock.exam || !!mock.finishedAt);
  const isFrialUser = state.isFrialUser;
  const level = getLevelById(state, lesson?.levelId);
  const levelGroupPosition = getLevelGroupByClassroom(
    state,
    level?.levelGroupId
  )?.position;
  const purchasedLevels = state.purchasedLevels;
  const showInsufficientMedcoinsModal =
    isFrialUser &&
    !purchasedLevels.includes(lesson?.levelId) &&
    levelGroupPosition > 1;

  return {
    lesson,
    currentClassroomId: state.currentClassroomId,
    hasClassroom: currentClassroom(state)?.id,
    mockId: mock?.id,
    numberOfRatings,
    userId: state.currentUserId,
    user: getCurrentUser(state),
    classroom: currentClassroom(state),
    levelGroupPosition,
    isWatched: makeIsWatched(state, { lessonId: lesson?.id }),
    prevLesson: getPrevLesson(state),
    nextLesson: getNextLesson(state),
    canExportMock,
    lassonWatchedState,
    canComment: !isLessonMock,
    canMarkAsWatched: !isLessonMock,
    isFrialUser,
    expiredUser: isExpiredUser(state),
    isPreExam,
    showInsufficientMedcoinsModal,
  };
}

export default connect(mapStateToProps)(LessonContainer);
