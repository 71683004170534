import React from "react";
import { IoArrowForward } from "react-icons/io5";
import { convertDateToString } from "../../../../utils/formatDate";
import { Popover } from "../../../Popover";
import { CheckIcon, PinIcon } from "../../icons";
import { NotificationsIcon } from "../NotificationsIcon";
import * as S from "./styles";

/**
 * Renderiza item de notificação.
 *
 * @param {object} props
 * @param {string} props.id - ID da notificação.
 * @param {boolean} props.wasRead - Se verdadeiro, a notificação foi lida.
 * @param {string} props.description - Descrição da notificação.
 * @param {string} props.title - Título da notificação.
 * @param {string} props.link - Link para redirecionamento.
 * @param {string} props.linkText - Texto do link.
 * @param {boolean} props.isPinned - Se verdadeiro, a notificação é fixada.
 * @param {string} props.createdAt - Data de criação da notificação.
 * @param {'achievements' | 'events' | 'materials' | 'roadmap' | 'news' | 'offensive' | 'security' | 'suggestion' | 'warnings'} [props.category] - Define a categoria da notificação.
 * @param {() => void} props.handlePin - Disparado ao clicar em pinar no popover
 * @param {() => void} props.handleDelete - Disparado ao clicar em deletar no popover
 */

export function NotificationItem({
  id,
  wasRead,
  description = "",
  title = "",
  link,
  linkText,
  isPinned,
  createdAt,
  category,
  handlePin,
  handleDelete,
  onClick,
}) {
  return (
    <S.Container wasRead={wasRead} onClick={onClick}>
      {category && (
        <NotificationsIcon variant={category.toLowerCase()} wasRead={wasRead} />
      )}

      <S.ContentWrapper>
        <S.Header>
          <S.TitleWrapper wasRead={wasRead}>
            <div>
              <h3>{title}</h3>
              {wasRead && <CheckIcon />}
            </div>
            <S.DateWrapper>
              <div>
                {createdAt && <span>{convertDateToString(createdAt)}</span>}
              </div>
            </S.DateWrapper>
          </S.TitleWrapper>

          <S.ActionsWrapper>
            {isPinned && <PinIcon />}

            <Popover
              onPin={handlePin}
              onDelete={handleDelete}
              pinText={isPinned ? "Desafixar notificação" : "Fixar notificação"}
              deleteText="Excluir notificação"
            />
          </S.ActionsWrapper>
        </S.Header>
        <S.DescriptionWrapper>
          <S.Description>{description}</S.Description>

          {link && (
            <S.LinkWrapper>
              <S.StyledLink href={link}>{linkText}</S.StyledLink>
              <IoArrowForward size={20} />
            </S.LinkWrapper>
          )}
        </S.DescriptionWrapper>
      </S.ContentWrapper>
    </S.Container>
  );
}
