import { setCookie } from "cookies-next";
import React, { useEffect, useState } from "react";
import { IoInformationCircle } from "react-icons/io5";
import { medtrackApi } from "../../../../../services/medtrack";
import { trackClickEvents } from "../../../../../services/mixpanel/events/trackClickEvents";
import { Divider } from "../../../../components/Divider";
import { apiGet } from "../../../../processes/helpers/api";
import { CheckIfIsLoading } from "../../CheckIfIsLoading";
import { useDispatch } from "react-redux";
import {
  BigAreaTag,
  LateStudiesModal,
  MedTrackAccordions,
  MedTrackTooltip,
  ModalBigArea,
  RecalculateRouteButton,
  ScrollTopButton,
  Skeleton,
  TimeAvailability,
  TodoItemsCategoryFilters,
  TodoItemsStatusAndPeriodFilter,
} from "../../components";
import { FeedbackModal } from "../../components/FeedbackModal";
import { LoadMoreButton } from "../../components/LoadMoreButton";
import { ModalSettingsButton } from "../../components/ModalSettingsButton";
import { ButtonsContainer } from "../../components/Skeleton/styles";
import { useMedTrack } from "../../hooks/useMedTrack";

import {
  CheckboxSlider,
  Container,
  ContentContainer,
  FooterContainer,
  Header,
  MedtrackRevisionMode,
  MedtrackRevisionWrapper,
  RevisionSwitch,
  TitleWrapper,
} from "./styles";
import { ModalActiveRevision } from "../../components/ModalActiveRevision";

export function MedTrackMainScreen({
  availableStudyTime,
  onChangeAvailableStudyTime,
  onUpdateAvailableTime,
  onGetAvailableTime,
  isAvailableTimeModalOpen,
  setIsAvailableTimeModalOpen,
  isAvailableTimeLoading,
  isBases,
  isR3CM,
  hasRevisionMedtrack,
  hasDefaultMedtrack,
  userMedtrackOptions,
  setShowActiveRevisionModal,
  showActiveRevisionModal,
  isFirstAccess,
  showOnboardingModal,
}) {
  const [isBigAreaModalOpen, setIsBigAreaModalOpen] = useState(false);
  const [shouldAskFeedbackModal, setShouldAskFeedbackModal] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isBasesAndHasNoTitle, setIsBasesAndHasNoTitle] = useState(false);
  const [isShowConfigurationDropdown, setIsShowConfigurationDropdown] =
    useState(false);
  const {
    todoGroups,
    setTodoGroups,
    getTodoGroups,
    isLoadingTodoGroups,
    basesBigArea,
    activeFilters,
    isRecalculateButtonAvailable,
    setCursor,
    checkIfCanEnableRecalculateButton,
    shouldResetFilters,
    getBasesBigArea,
    getFiltersQueryParams,
    getSchedule,
    updateActiveSchedule,
    setSelectedMedtrackType,
    selectedMedtrackType,
    getLastUserAction,
    fetchSchedule,
    isLoadingSchedule,
  } = useMedTrack();
  const revisionMode = selectedMedtrackType.type === "REVIEW";
  const basesMode = selectedMedtrackType.type === "bases";
  const dispatch = useDispatch();
  const shouldShowSwitch = hasRevisionMedtrack && hasDefaultMedtrack;
  function handleBlockBasesRecalculateBtn() {
    if (isBases) {
      const lateTodoGroup = todoGroups.filter((todo) => todo.tag === "late");

      if (lateTodoGroup.length > 0) {
        const hasItemsWithoutTitle = lateTodoGroup.some((todoGroup) =>
          todoGroup.items.some(
            (item) => item.type.category === "theory" && item.title.length <= 0
          )
        );

        setIsBasesAndHasNoTitle(hasItemsWithoutTitle);
      }
    }
  }
  async function checkIfShouldAskForFeedbackModal(email, accessToken, client) {
    const response = await apiGet(
      `/api/v1/feedback?email=${email}&access_token=${accessToken}&client=${client}&product=MEDTRACK`
    );
    const shouldAsk = response.body.should_show_feedback;
    setShouldAskFeedbackModal(shouldAsk);
  }
  async function handleLoadMore() {
    if (activeFilters.cursor == null) return;

    setIsLoadingMore(true);

    try {
      const filterQueries = getFiltersQueryParams();
      const activeScheduleId = selectedMedtrackType.id;
      const url = `/todo-group?${filterQueries}&scheduleId=${activeScheduleId}`;

      const response = await medtrackApi.get(url);

      const data = response.body.data;
      const cursor = response.body.pagination.next;

      setCursor(cursor);
      if (data) setTodoGroups((prev) => [...prev, ...data]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMore(false);
    }
  }

  async function handleRecalculateRoute() {
    await getTodoGroups();
    await checkIfCanEnableRecalculateButton(selectedMedtrackType.id);
  }

  function toggleConfigurationDropDown() {
    setIsShowConfigurationDropdown(!isShowConfigurationDropdown);
  }

  function closeConfigurationDropDown() {
    setIsShowConfigurationDropdown(false);
  }

  function openAvailableTimeModal() {
    trackClickEvents({
      tagName: "Medtrack",
      eventName: "interação de navegação",
      content: {
        NomeDoBotao: "Configurações",
      },
    });
    setIsAvailableTimeModalOpen(true);
  }

  function openBigAreaModal() {
    setIsBigAreaModalOpen(true);
  }
  const defaultMedtrackUserType =
    userMedtrackOptions?.length > 0
      ? userMedtrackOptions.find((option) => option !== "revisao")
      : userMedtrackOptions;

  async function updateActiveMedtrackType() {
    const schedules = await getSchedule();
    const activeSchedule = schedules.find((schedule) => schedule.isActive);
    if (activeSchedule.type === "REVIEW") {
      dispatch({
        type: "MEDTRACK_TYPE",
        payload: {
          activeMedtrackType: "revisao",
        },
      });
    } else {
      dispatch({
        type: "MEDTRACK_TYPE",
        payload: {
          activeMedtrackType: defaultMedtrackUserType,
        },
      });
    }
  }

  function toggleSelectedMedtrackType(selectedType, scheduleId) {
    setCursor(null);
    setSelectedMedtrackType({
      type: selectedType,
      id: scheduleId,
    });
  }

  async function handleToggleMedtrackType() {
    const schedules = await getSchedule();
    const defaultScheduleId = schedules.find(
      (schedule) => schedule.type === "DEFAULT"
    )?.id;
    const revisionScheduleId = schedules.find(
      (schedule) => schedule.type === "REVIEW"
    )?.id;

    if (revisionMode) {
      toggleSelectedMedtrackType(defaultMedtrackUserType, defaultScheduleId);
      dispatch({
        type: "MEDTRACK_TYPE",
        payload: {
          activeMedtrackType: defaultMedtrackUserType,
        },
      });
      if (defaultScheduleId) await updateActiveSchedule(defaultScheduleId);
    } else {
      // onGetLastUserAction();
      toggleSelectedMedtrackType("REVIEW", revisionScheduleId);
      dispatch({
        type: "MEDTRACK_TYPE",
        payload: {
          activeMedtrackType: "revisao",
        },
      });
      if (revisionScheduleId) await updateActiveSchedule(revisionScheduleId);
    }
  }

  // async function onGetLastUserAction() {
  //   try {
  //     const response = await getLastUserAction();
  //     const { action } = response.body;

  //     if (action === "DONT_SHOW_REVISION_MODE_MODAL") {
  //       setShowActiveRevisionModal(false);
  //     } else {
  //       setShowActiveRevisionModal(true);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     setShowActiveRevisionModal(true);
  //   }
  // }

  useEffect(() => {
    fetchSchedule();
  }, []);

  useEffect(() => {
    if (!isFirstAccess) updateActiveMedtrackType();
  }, []);

  useEffect(() => {
    getTodoGroups();
  }, [activeFilters.category, selectedMedtrackType]);

  useEffect(() => {
    if (shouldResetFilters) getTodoGroups();
  }, [shouldResetFilters]);

  useEffect(() => {
    handleBlockBasesRecalculateBtn();
  }, [todoGroups]);

  useEffect(() => {
    if (basesMode) getBasesBigArea();
  }, [basesMode]);

  useEffect(() => {
    // TODO: abstrair definição de cookies métrico pois é usado em mais lugares
    const session = JSON.parse(localStorage.getItem("session"));
    const userPlatformID = session.currentUserId;
    const email = session.headers.uid;
    const accessToken = session.headers["access-token"];
    const client = session.headers.client;
    checkIfShouldAskForFeedbackModal(email, accessToken, client);

    setCookie(process.env.METRICO_USER_ID_COOKIE, userPlatformID, {
      domain: process.env.DOMAIN_URL,
    });
  }, []);

  return (
    <>
      {/* {showActiveRevisionModal && (
        <ModalActiveRevision
          onClose={() => setShowActiveRevisionModal(false)}
        />
      )} */}
      {!showOnboardingModal && (
        <LateStudiesModal onRecalculateRoute={handleRecalculateRoute} />
      )}

      <Container>
        <Header isRevisionModeActive={revisionMode}>
          <TitleWrapper isRevisionModeActive={revisionMode}>
            <h3>{revisionMode ? "MedTrack Revisão" : "MedTrack"}</h3>

            {!revisionMode && (
              <MedTrackTooltip
                width="336px"
                variant="titleInfo"
                text="O Medtrack é um mentor virtual que orienta exatamente o que e como você deve estudar para potencializar seus resultados. Ele se adapta totalmente à sua disponibilidade de tempo para estudar e você consegue Recalcular Rota sempre que precisar."
              >
                <IoInformationCircle size={20} fill="#C2CED6" />
              </MedTrackTooltip>
            )}
          </TitleWrapper>

          {shouldShowSwitch && (
            <MedtrackRevisionWrapper>
              <MedtrackRevisionMode
                htmlFor="revision-switch"
                isRevisionModeActive={revisionMode}
              >
                <RevisionSwitch data-active={revisionMode}>
                  <input
                    disabled={isLoadingSchedule || isLoadingTodoGroups}
                    onChange={handleToggleMedtrackType}
                    type="checkbox"
                    id="revision-switch"
                    checked={revisionMode}
                  />
                  <CheckboxSlider />
                </RevisionSwitch>

                <i>Modo Revisão</i>

                <MedTrackTooltip
                  width="217px"
                  variant="revisionMode"
                  text="Você pode ativar e desativar quando quiser o modo Revisão."
                >
                  <IoInformationCircle size={20} fill="#C2CED6" />
                </MedTrackTooltip>
              </MedtrackRevisionMode>
            </MedtrackRevisionWrapper>
          )}

          {basesMode && <BigAreaTag bigArea={basesBigArea} />}

          <MedTrackTooltip
            isHidden={isRecalculateButtonAvailable}
            variant="recalculateButton"
            width="232px"
            title="A ação de recalcular está desativada."
            text="Você já recalculou nesta semana ou não possui nenhuma tarefa atrasada."
          >
            <RecalculateRouteButton
              onRecalculateRoute={handleRecalculateRoute}
              disabled={!isRecalculateButtonAvailable || isBasesAndHasNoTitle}
            />
          </MedTrackTooltip>

          <ModalSettingsButton
            isBases={basesMode}
            toggleConfigurationDropDown={toggleConfigurationDropDown}
            isShowConfigurationDropdown={isShowConfigurationDropdown}
            closeConfigurationDropDown={closeConfigurationDropDown}
            openAvailableTimeModal={openAvailableTimeModal}
            openBigAreaModal={openBigAreaModal}
          />
        </Header>

        <div style={{ paddingInline: "24px" }}>
          <Divider />
        </div>
        <CheckIfIsLoading
          placeholder={<Skeleton />}
          isLoading={isLoadingTodoGroups}
        >
          <ContentContainer>
            <ButtonsContainer>
              <TodoItemsCategoryFilters isRevision={revisionMode} />
              <TodoItemsStatusAndPeriodFilter
                isR3CM={isR3CM}
                isRevision={revisionMode}
                onFilter={() => {
                  setCursor(null);
                  getTodoGroups();
                }}
              />
            </ButtonsContainer>

            <MedTrackAccordions
              isLoading={isLoadingSchedule}
              isBases={basesMode}
              isRevision={revisionMode}
            />

            {todoGroups.length > 0 && (
              <FooterContainer>
                <LoadMoreButton
                  isEmptyData={activeFilters.cursor == null}
                  onClick={handleLoadMore}
                  isLoading={isLoadingMore}
                />
                <ScrollTopButton isFloating={false} />
              </FooterContainer>
            )}
          </ContentContainer>
        </CheckIfIsLoading>
      </Container>

      {/* ========== Modais Root* ========== */}
      <TimeAvailability
        availableStudyTime={availableStudyTime}
        onChangeAvailableTime={onChangeAvailableStudyTime}
        onGetAvailableTime={onGetAvailableTime}
        onUpdateAvailableTime={onUpdateAvailableTime}
        isAvailableTimeModalOpen={isAvailableTimeModalOpen}
        isAvailableTimeLoading={isAvailableTimeLoading}
        setIsAvailableTimeModalOpen={setIsAvailableTimeModalOpen}
      />

      {basesMode && (
        <ModalBigArea
          isOpen={isBigAreaModalOpen}
          setIsOpen={setIsBigAreaModalOpen}
          isFirstAccess={isFirstAccess}
        />
      )}
      {shouldAskFeedbackModal && <FeedbackModal />}
    </>
  );
}
