import React from "react";
import { colors } from "../../../components/library/theme";

export function Illustration(props) {
  return (
    <svg
      {...props}
      width="320"
      height="209"
      viewBox="0 0 320 209"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_252_11233)">
        <path
          d="M313.982 107H63.0179C61.9523 107 60.9303 107.395 60.1768 108.099C59.4233 108.802 59 109.756 59 110.751C59 111.746 59.4233 112.7 60.1768 113.404C60.9303 114.108 61.9523 114.503 63.0179 114.503H70.1265V204.248C70.1265 205.243 70.5498 206.198 71.3033 206.901C72.0568 207.605 73.0788 208 74.1444 208C75.21 208 76.232 207.605 76.9855 206.901C77.739 206.198 78.1623 205.243 78.1623 204.248L78.2234 114.503H279.648L298.22 204.248C298.22 205.243 298.643 206.198 299.396 206.901C300.15 207.605 301.172 208 302.237 208C303.303 208 304.325 207.605 305.079 206.901C305.832 206.198 306.255 205.243 306.255 204.248L307.424 114.503H313.982C315.048 114.503 316.07 114.108 316.823 113.404C317.577 112.7 318 111.746 318 110.751C318 109.756 317.577 108.802 316.823 108.099C316.07 107.395 315.048 107 313.982 107Z"
          fill="#CCCCCC"
        />
        <path
          d="M197.166 101.223C197.677 100.753 198.285 100.4 198.947 100.189C199.61 99.9781 200.31 99.9147 201 100.003C201.69 100.092 202.351 100.33 202.939 100.701C203.526 101.072 204.025 101.567 204.399 102.151L215.1 100.254L217.842 106.457L202.693 108.924C201.667 109.487 200.47 109.655 199.329 109.397C198.187 109.138 197.18 108.471 196.499 107.522C195.818 106.572 195.509 105.407 195.632 104.246C195.755 103.085 196.301 102.01 197.166 101.223Z"
          fill="#FFB6B6"
        />
        <path
          d="M207.073 109.144L218.229 109.005L219.641 108.986L242.338 108.701C242.338 108.701 248.501 102.838 253.868 96.801L253.116 91.3756C252.382 86.051 249.733 81.1736 245.663 77.6512C237.976 82.7728 233.518 92.9104 233.518 92.9104L219.445 96.2668L218.038 96.6025L209.293 98.6861L207.073 109.144Z"
          fill={colors.brand.pure}
        />
        <path
          d="M264.181 142.491H243.357C243.357 143.381 220.594 144.103 220.594 144.103C220.092 144.48 219.635 144.912 219.23 145.392C218.015 146.837 217.351 148.664 217.355 150.55V151.839C217.356 153.977 218.208 156.026 219.723 157.537C221.238 159.048 223.294 159.898 225.437 159.898H264.181C266.324 159.897 268.378 159.047 269.894 157.536C271.409 156.025 272.261 153.976 272.263 151.839V150.55C272.26 148.413 271.407 146.365 269.892 144.855C268.377 143.344 266.324 142.494 264.181 142.491Z"
          fill="#E4E4E4"
        />
        <path
          d="M249.038 159.578H240.633V207.956H249.038V159.578Z"
          fill="#E4E4E4"
        />
        <path
          d="M216.71 207.662C216.71 208.567 229.447 208.012 245.159 208.012C260.871 208.012 273.607 208.567 273.607 207.662C273.607 206.756 260.871 199.575 245.159 199.575C229.447 199.575 216.71 206.756 216.71 207.662Z"
          fill="#E4E4E4"
        />
        <path
          d="M211.064 202.134L204.809 202.134L201.833 178.074L211.065 178.074L211.064 202.134Z"
          fill="#FFB6B6"
        />
        <path
          d="M212.659 208.181L192.489 208.18V207.925C192.489 205.849 193.316 203.858 194.788 202.39C196.261 200.922 198.258 200.097 200.34 200.097H200.34L212.66 200.097L212.659 208.181Z"
          fill="#2F2E41"
        />
        <path
          d="M192.575 202.134L186.32 202.134L183.344 178.074L192.576 178.074L192.575 202.134Z"
          fill="#FFB6B6"
        />
        <path
          d="M194.17 208.181L174 208.18V207.925C174 205.849 174.827 203.858 176.3 202.39C177.772 200.922 179.769 200.097 181.851 200.097H181.851L194.171 200.097L194.17 208.181Z"
          fill="#2F2E41"
        />
        <path
          d="M248.61 63.6181C254.617 63.6181 259.487 58.7623 259.487 52.7723C259.487 46.7824 254.617 41.9265 248.61 41.9265C242.603 41.9265 237.734 46.7824 237.734 52.7723C237.734 58.7623 242.603 63.6181 248.61 63.6181Z"
          fill="#FFB6B6"
        />
        <path
          d="M266.215 127.083C266.215 127.083 269.864 146.326 255.979 148.777C242.093 151.227 222.049 152.949 222.049 152.949L213.048 194.826L200.955 193.749C200.955 193.749 202.563 143.339 206.518 139.395C210.474 135.451 235.471 123.642 235.471 123.642L266.215 127.083Z"
          fill="#2F2E41"
        />
        <path
          d="M239.095 119.018L233.022 125.093C233.022 125.093 189.173 132.136 187.481 142.236C184.927 157.483 181.911 190.639 181.911 190.639L195.094 189.653L200.264 151.963L221.358 147.179L245.357 134.137L239.095 119.018Z"
          fill="#2F2E41"
        />
        <path
          d="M259.077 46.2982C258.003 44.2782 256.639 40.2524 254.472 39.5057C253.269 39.1078 252.025 38.8436 250.763 38.7178L247.08 40.1305L249.084 38.5758C248.694 38.5526 248.304 38.5336 247.914 38.5189L245.429 39.4721L246.7 38.4859C244.383 38.4622 241.992 38.7025 240.196 40.109C238.666 41.308 237.149 45.8502 236.904 47.7756C236.642 49.6175 236.734 51.4923 237.175 53.3L237.804 53.8967C238.024 53.3933 238.19 52.8679 238.298 52.3294C238.523 51.3232 238.957 50.3749 239.57 49.545C240.184 48.715 240.964 48.0216 241.861 47.509L241.895 47.4897C242.951 46.8973 244.232 46.9246 245.442 46.968L251.192 47.1743C252.575 47.224 254.064 47.3112 255.143 48.1764C255.828 48.8147 256.367 49.5932 256.722 50.4585C257.258 51.5369 258.305 55.6046 258.305 55.6046C258.305 55.6046 258.898 54.8368 259.182 55.4083C259.637 53.9172 259.914 52.3777 260.007 50.8218C260.097 49.2589 259.777 47.6996 259.077 46.2982Z"
          fill="#2F2E41"
        />
        <path
          d="M297.119 57.5352C296.425 57.5807 295.75 57.7774 295.14 58.1114C294.531 58.4454 294.003 58.9085 293.592 59.4682C293.182 60.0279 292.899 60.6705 292.765 61.3508C292.631 62.031 292.647 62.7324 292.814 63.4055L284.068 69.839L286.692 76.0926L298.916 66.8343C300.031 66.4799 300.978 65.7298 301.575 64.7262C302.173 63.7225 302.381 62.5351 302.16 61.3888C301.938 60.2426 301.303 59.2172 300.374 58.507C299.445 57.7967 298.287 57.4509 297.119 57.5352Z"
          fill="#FFB6B6"
        />
        <path
          d="M295.711 70.6694L273.784 94.9521C273.784 94.9521 263.299 91.6049 255.189 87.976L251.546 76.8184C250.7 74.2299 250.339 71.5084 250.481 68.7896C261.723 65.8067 274.799 76.9058 274.799 76.9058L291.212 64.0861L295.711 70.6694Z"
          fill={colors.brand.pure}
        />
        <path
          d="M244.548 128.877C240.636 127.529 236.591 126.136 232.228 125.613L232.041 125.59L232.092 125.409C236.609 109.596 235.482 99.4805 233.268 84.2854C232.797 81.0079 233.402 77.6664 234.992 74.7597C236.583 71.8531 239.073 69.5371 242.091 68.1575L242.118 68.1455L254.579 67.3559L254.608 67.3542L263.67 70.0121C264.974 70.3973 266.115 71.1981 266.919 72.2918C267.723 73.3855 268.145 74.7118 268.12 76.0674C268.022 81.0646 268.23 86.6438 268.451 92.5505C268.945 105.802 269.456 119.504 266.555 128.395L266.539 128.441L266.501 128.472C263.833 130.638 260.491 131.809 257.05 131.782C252.982 131.782 248.831 130.352 244.548 128.877Z"
          fill={colors.brand.pure}
        />
        <path
          d="M310.809 209.295H0.197965C0.078575 209.295 -0.0359243 209.241 -0.120346 209.144C-0.204767 209.048 -0.252197 208.917 -0.252197 208.781C-0.252197 208.644 -0.204767 208.513 -0.120346 208.417C-0.0359243 208.321 0.078575 208.266 0.197965 208.266H310.809C310.929 208.266 311.043 208.321 311.128 208.417C311.212 208.513 311.26 208.644 311.26 208.781C311.26 208.917 311.212 209.048 311.128 209.144C311.043 209.241 310.929 209.295 310.809 209.295Z"
          fill="#CCCCCC"
        />
        <path
          d="M7.86273 168.939C9.68664 169.578 11.6449 169.738 13.5489 169.404C15.4529 169.069 17.2384 168.252 18.7335 167.029C22.541 163.843 23.7348 158.592 24.7061 153.731C25.6638 148.938 26.6215 144.145 27.5791 139.352L21.5642 143.482C17.2386 146.452 12.8156 149.517 9.82055 153.819C6.82553 158.121 5.51819 163.994 7.92459 168.649"
          fill="#E6E6E6"
        />
        <path
          d="M8.37727 186.347C7.76848 181.925 7.14248 177.446 7.56951 172.977C7.94877 169.008 9.16315 165.131 11.6355 161.952C12.9478 160.268 14.5447 158.825 16.3542 157.688C16.8261 157.391 17.2604 158.137 16.7906 158.433C13.6595 160.406 11.2377 163.32 9.87444 166.754C8.3687 170.573 8.1269 174.736 8.38638 178.793C8.54329 181.246 8.87585 183.684 9.21097 186.118C9.23739 186.228 9.22091 186.344 9.16486 186.442C9.10882 186.541 9.01738 186.614 8.90905 186.648C8.79852 186.678 8.6807 186.663 8.58115 186.606C8.4816 186.55 8.40833 186.457 8.37727 186.347Z"
          fill="#F2F2F2"
        />
        <path
          d="M14.2407 178.522C15.023 179.708 16.0982 180.673 17.3629 181.323C18.6275 181.974 20.0389 182.289 21.4612 182.239C25.1163 182.066 28.1635 179.522 30.9066 177.107L39.02 169.963L33.6502 169.707C29.7887 169.523 25.8274 169.351 22.1494 170.538C18.4714 171.726 15.0793 174.584 14.4067 178.38"
          fill="#E6E6E6"
        />
        <path
          d="M6.65677 188.889C9.58698 183.719 12.9858 177.973 19.0588 176.136C20.7474 175.628 22.5181 175.445 24.2753 175.599C24.8292 175.647 24.6909 176.498 24.138 176.451C21.1925 176.207 18.2526 176.982 15.8133 178.647C13.4661 180.24 11.6385 182.455 10.0916 184.804C9.14411 186.243 8.2954 187.742 7.44652 189.24C7.17524 189.719 6.38232 189.373 6.65677 188.889Z"
          fill="#F2F2F2"
        />
        <path
          d="M1.96146 194.331C2.06019 193.19 2.47092 192.099 3.14893 191.175C3.82694 190.25 4.74622 189.53 5.80667 189.091V186.102H10.8341V189.185C11.8423 189.645 12.71 190.364 13.3487 191.269C13.9875 192.173 14.3743 193.229 14.4699 194.331L15.652 208.034H0.779297L1.96146 194.331Z"
          fill="#CCCCCC"
        />
        <path
          d="M166.187 74.6667C166.187 109.564 137.897 137.854 103 137.854C68.1027 137.854 39.8129 109.564 39.8129 74.6667C39.8129 39.7694 68.1027 11.4795 103 11.4795C137.897 11.4795 166.187 39.7694 166.187 74.6667Z"
          fill="white"
          stroke={colors.brand.pure}
          stroke-width="3.6257"
        />
        <path
          d="M94.1325 108.496C93.0101 108.496 91.903 108.235 90.8991 107.733C89.8952 107.231 89.0221 106.502 88.3488 105.604L70.6158 81.9592C70.0462 81.1996 69.6317 80.3352 69.3961 79.4154C69.1605 78.4957 69.1083 77.5385 69.2426 76.5985C69.3769 75.6586 69.6951 74.7543 70.1788 73.9373C70.6626 73.1204 71.3026 72.4067 72.0622 71.837C72.8218 71.2673 73.6862 70.8529 74.606 70.6172C75.5257 70.3816 76.4829 70.3295 77.4229 70.4638C78.3628 70.5981 79.2671 70.9162 80.0841 71.4C80.901 71.8838 81.6147 72.5238 82.1844 73.2834L93.7859 88.7509L123.583 44.0559C124.647 42.4616 126.301 41.3552 128.181 40.9799C130.061 40.6045 132.012 40.991 133.607 42.0543C135.202 43.1175 136.31 44.7707 136.686 46.6502C137.063 48.5297 136.678 50.4818 135.615 52.0774L100.149 105.276C99.5077 106.239 98.6448 107.033 97.6329 107.593C96.6211 108.153 95.4897 108.462 94.3338 108.494C94.2667 108.495 94.1996 108.496 94.1325 108.496Z"
          fill={colors.brand.pure}
        />
      </g>
      <defs>
        <clipPath id="clip0_252_11233">
          <rect
            width="320"
            height="208"
            fill="white"
            transform="translate(0 0.666656)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
